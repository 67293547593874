import { useEffect, useRef } from 'react';
import styles from './chemical-description.module.css';
import { stream } from './stream';
//types:
// import { AppData } from "/types";

// Fn Description
/* 
Child Fn's

 resetState() => re-sets all local state to their initial values

 getTokens() 
    . connectSocket() 
      - timeoutId to retry connection 
      - webSocket handlers => .onopen, .onmessage, .onerror, .onclose
      - called re-cursively by .onerror and by timeoutId setInterval
  ;

  getCacheAndHydrateSummary() 

  useEffect => Hydrate summary via calling getTokens or getCacheAndHydrateSummary once we have searchResults

  useEffect => Reset when submit count changes

JSX Fn's
  . Tokens()

socketRef is cleared back to null in: 
  - reset()
  - .onerror
  - top of connectSocket()
  - NOT cleared to null .onclose
  
Multiple record logic
  - If submitCount increases while a record is streaming 
    - an abort message is sent to the server.
    - server aborts it's request to LLM.
    - socketRef.current is closed.
    - socketRef.current is cleared to null.
*/

export function ChemicalDescription({

  isSaved,

  opening,
  mechanism,
  toxicity,

  streamed,

  loading, 
  setLoading,

  domNodes,

  setRenderProperties

}: any) {


  async function renderSavedDescription() {

    // Timeout added to prevent a stream overlap when a new saved record is searched as a previous saved record is still rendering. 
    // This timeout should allow enough time for previous stream's promise to reject after an error is thrown because 
    // it can no longer find the domNode to hydrate as when loading is switched true the div's with the domNodes will be unmounted.
    setTimeout( async() => {

      setLoading( false );

      try {

        await stream( opening, domNodes.descriptions.opening, "quad", 12 );
  
        await stream(
          "Molecular Mechanism: ",
          domNodes.descriptions.mechanismTitle,
          "single",
          50
        );
  
        await stream(
          mechanism,
          domNodes.descriptions.mechanism,
          "quad",
          12
        );
    
        await stream(
          "Toxicity: ",
          domNodes.descriptions.toxicityTitle,
          "single",
          50
        );
  
        await stream(
          toxicity,
          domNodes.descriptions.toxicity,
          "quad",
          12
        );

        setRenderProperties( true );

      }

      catch(e) {

        // any error handling logic needed here? Or should we just leave the error caught?

      }
 
    }, 1000 );

  }

  
  
  useEffect( () => {

    if ( isSaved === true && streamed === false ) renderSavedDescription();

  }, [ toxicity ] ); // toxicity is the last variable set when we get back a savedRecord in useSearch


  
	return (
    
		loading === true
    ? 
		< DescriptionSpinner />
		: 
    < div >
      { 
        isSaved ?
        // Mount saved description JSX that will get faux streamed with the domNodes
        <>

          <div id='opening' ref={ domNodes.descriptions.opening } className={ styles.opening }>
          </div>

          <div id='mechanism' className={ styles.mechanism }>

            <span ref={ domNodes.descriptions.mechanismTitle } className={ styles.descriptionBullet }></span>
            <span ref={ domNodes.descriptions.mechanism }></span>

          </div>

          <div id='toxicity' className={ styles.mechanism }>

            <span ref={ domNodes.descriptions.toxicityTitle } className={ styles.toxicityBullet }></span>
            <span ref={ domNodes.descriptions.toxicity }></span>

          </div>

        </>
        :
        // Mount new descriptipn JSX that will get updated by react's built in loop everytime useSearch sets opening, mechanism, or toxicity
        <>

          <div id='opening' ref={ domNodes.descriptions.opening } className={ styles.opening }>
            { opening ? opening.map( ( token: string, i: number ) => < span key={ i } >{ token }</ span > ) : "" }
          </div>

          <div id='mechanism' ref={ domNodes.descriptions.mechanism } className={ styles.mechanism }>

            <span ref={domNodes.descriptions.mechanismTitle} className={styles.descriptionBullet}>Molecular Mechanism:{' '}</span>
            { mechanism ? mechanism.map( ( token: string, i: number ) => < span key={ i } >{ token }</ span > ) : "" }
            
          </div>

          <div id='toxicity' ref={ domNodes.descriptions.toxicity } className={ styles.mechanism }>

            <span className={styles.toxicityBullet}>Toxicity: </span>
            { toxicity ? toxicity.map( ( token: string, i: number ) => < span key={ i } >{ token }</ span > ) : "" }

          </div>

        </>
      }
    </ div >

	);
  
}



function SavedDescription( { domNodes }: any ): JSX.Element {

  return (
    <>

      <div id='opening' ref={ domNodes.descriptions.opening } className={ styles.opening }>
      </div>

      <div id='mechanism' className={ styles.mechanism }>

        <span ref={ domNodes.descriptions.mechanismTitle } className={ styles.descriptionBullet }></span>
        <span ref={ domNodes.descriptions.mechanism }></span>

      </div>

      <div id='toxicity' className={ styles.mechanism }>

        <span ref={ domNodes.descriptions.toxicityTitle } className={ styles.toxicityBullet }></span>
        <span ref={ domNodes.descriptions.toxicity }></span>

      </div>

    </>

  );

};



function NewDescription( { domNodes, opening, mechanism, toxicity }: any ): JSX.Element {

  return (

    <>

      <div id='opening' ref={ domNodes.descriptions.opening } className={ styles.opening }>
        { opening ? opening.map( ( token: string, i: number ) => < span key={ i } >{ token }</ span > ) : "" }
      </div>

      <div id='mechanism' ref={ domNodes.descriptions.mechanism } className={ styles.mechanism }>
        { mechanism ? mechanism.map( ( token: string, i: number ) => < span key={ i } >{ token }</ span > ) : "" }
      </div>

      <div id='toxicity' ref={ domNodes.descriptions.toxicity } className={ styles.mechanism }>
        { toxicity ? toxicity.map( ( token: string, i: number ) => < span key={ i } >{ token }</ span > ) : "" }
      </div>

    </>


  );

};



function DescriptionSpinner(): JSX.Element {

  const elementRef = useRef< HTMLDivElement | null >( null );

  useEffect( () => {

    let currentDotsCount = 0;

    const timer = setInterval( () => {
      if (elementRef.current) {
        if (currentDotsCount < 3) {
          elementRef.current.textContent += '.';
          currentDotsCount += 1;
        } else {
          elementRef.current.textContent = '';
          currentDotsCount = 0;
        }
      }
    }, 350);

    return () => {
      clearInterval( timer );
    };

  }, [] );


  return (

    < div className={ styles.descriptionSpinner } >

      < p style={ { display: 'inline', margin: '0', padding: '0' } } >Loading</ p >
      < p style={ { display: 'inline' } } ref={ elementRef }></p>

    </div>

  );

}

















{/* <span ref={ domNodes.descriptions.mechanismTitle } className={ styles.descriptionBullet }>Molecular Mechanism:{' '}</span> */}
{/* { mechanismTokens?.length ? ( <span ref={domNodes.descriptions.mechanismTitle} className={styles.descriptionBullet}>Molecular Mechanism:{' '}</span> ) : ( '' )} */}


{/* <span className={ styles.toxicityBullet }>Toxicity: </span> */}
{/* { toxicityTokens?.length ? ( <span className={styles.toxicityBullet}>Toxicity: </span> ) : ( '' ) } */}













 

// set timeout inside DescriptionSpinner to terminate loading state if no data is received after 10 seconds
/*
    let loadingTimeout = setTimeout(() => {
      console.log('Stuck in loading state data for 10 seconds.');
      setError("Loading timed out. Please try again.");
      setLoading(false);


    }, 10000);
    */

// setTimeout( () => {
//   webSocket!.send( JSON.stringify("close") )
//   console.log("Sent close message to client after 5 seconds");
// }, 5000 )

/*
  function readyToRenderDescription(): boolean {
    if (!streamed && !streaming) {
      return true;
    } else return false;
  };
  */

// useEffect( () => {

//   webSocket?.close();
//   console.log( "webSocket inside useEffect" ,webSocket);
//   console.log("Closing socket in useEffect");

// }, [ submitCount ] )

{
  /* < OpeningTokens />
  < MechanismTokens />
  < ToxicityTokens /> */
}