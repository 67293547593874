import styles from './chemical-properties.module.css';
import { stream } from './stream';
import { useEffect } from 'react';


export function ChemicalProperties( { properties, isSaved, domNodes, loading, streamed, renderProperties, setStreamed }: any ): JSX.Element {

  useEffect( () => {

    if ( properties && !loading && renderProperties === true && streamed === false ) {

      renderChemicalProperties( properties, domNodes );
      setStreamed( true );

    }

  }, [ properties, loading, renderProperties ] );


  return (

    !renderProperties
    ?
    <></>
    :
    < div >
    {
      isSaved 
      ?
      <div id='table-wrapper' ref={ domNodes.properties.wrapper } className={ styles.propertiesWrapper }>
  
        <table id='properties-table' className={`${ streamed ? '' : styles.hidden } `}>
          
          <tbody>
  
            <tr>
              <th colSpan={ 2 }>Chemical Properties:</th>
            </tr>

            <tr key={ 1 }>
              < td ref={ domNodes.properties.names[ 1 ] }></td>
              < td ref={ domNodes.properties.values[ 1 ] }></td>
            </tr>

            <tr key={ 2 }>
              < td ref={ domNodes.properties.names[2]}></td>
              < td ref={ domNodes.properties.values[2]}></td>
            </tr>
            
            <tr key={ 3 }>
              < td ref={ domNodes.properties.names[3]}></td>
              < td ref={ domNodes.properties.values[3]}></td>
            </tr>

            <tr key={ 4 }>
              < td ref={ domNodes.properties.names[4]}></td>
              < td ref={ domNodes.properties.values[4]}></td>
            </tr>

            <tr key={ 5 }>
              < td ref={ domNodes.properties.names[5]}></td>
              < td ref={ domNodes.properties.values[5]}></td>
            </tr>

            <tr key={ 6 }>
              < td ref={ domNodes.properties.names[6]}></td>
              < td ref={ domNodes.properties.values[6]}></td>
            </tr>

            <tr key={ 7 }>
              < td ref={ domNodes.properties.names[7]}></td>
              < td ref={ domNodes.properties.values[7]}></td>
            </tr>

            <tr key={ 8 }>
              < td ref={ domNodes.properties.names[8]}></td>
              < td ref={ domNodes.properties.values[8]}></td>
            </tr>

            <tr key={ 9 }>
              < td ref={ domNodes.properties.names[9]}></td>
              < td ref={ domNodes.properties.values[9]}></td>
            </tr>

            <tr key={ 10 }>
              <td ref={ domNodes.properties.names[10]}></td>
              <td ref={ domNodes.properties.values[10]}></td>
            </tr>

            <tr key={ 11 }>
              <td ref={ domNodes.properties.names[11]}></td>
              <td ref={ domNodes.properties.values[11]}></td>
            </tr>
  
          </tbody>
  
        </table>
  
      </div>
      :
      <div id='table-wrapper' ref={domNodes.properties.wrapper} className={`${styles.propertiesWrapper}`}>
            
        <table id='properties-table'>
          {/* <table id='properties-table' className={`${ streamed && appData.searchResults.properties ? '' : styles.hidden }`}> */}
          <tbody>
    
              < tr >
                < th colSpan={2} >Chemical Properties:</ th >
              </ tr >

              < tr key={ 1 } >
                < td ref={domNodes.properties.names[1]}></ td >
                < td ref={domNodes.properties.values[1]}></ td >
              </ tr >

              < tr key={ 2 } >
                < td ref={domNodes.properties.names[2]}></ td >
                < td ref={domNodes.properties.values[2]}></ td >
              </ tr >

              < tr key={ 3 } >
                < td ref={domNodes.properties.names[3]} ></ td >
                < td ref={domNodes.properties.values[3]} ></ td >
              </ tr >

              < tr key={ 4 } >
                < td ref={domNodes.properties.names[4]} ></ td >
                < td ref={domNodes.properties.values[4]} ></ td >
              </ tr >

              < tr key={ 5 } >
                < td ref={domNodes.properties.names[5]} ></ td >
                < td ref={domNodes.properties.values[5]} ></ td >
              </ tr >

              < tr key={ 6 } >
                < td ref={domNodes.properties.names[6]}></ td >
                < td ref={domNodes.properties.values[6]}></ td >
              </ tr >

              < tr key={ 7 } >
                < td ref={domNodes.properties.names[7]}></ td >
                < td ref={domNodes.properties.values[7]}></ td >
              </ tr >
              
              < tr key={ 8 } >
                < td ref={domNodes.properties.names[8]}></ td >
                < td ref={domNodes.properties.values[8]}></ td >
              </ tr >

              < tr key={ 9 } >
                < td ref={domNodes.properties.names[9]}></ td >
                < td ref={domNodes.properties.values[9]}></ td >
              </ tr >

              < tr key={ 10 } >
                < td ref={domNodes.properties.names[10]}></ td >
                < td ref={domNodes.properties.values[10]}></ td >
              </ tr >

              < tr key={ 11 } >
                < td ref={domNodes.properties.names[11]}></ td >
                < td ref={domNodes.properties.values[11]}></ td >
              </ tr >
    
          </ tbody >
    
        </ table >
          
      </div>
    }
    </ div >

  );

  
};
  
  
async function renderChemicalProperties( properties: any, domNodes: any ) {

    const formattedChemicalProperties = propertyFormatter( properties );

    const propertyKeys = Object.keys( formattedChemicalProperties );

    const propertyValues = Object.values( formattedChemicalProperties );

    for ( let i = 1; i < domNodes.properties.names.length; i++ ) {

        await stream( propertyKeys[ i ], domNodes.properties.names[ i ], "single", 10 );

        await stream(
            propertyValues[ i ],
            domNodes.properties.values[ i ],
            "single",
            10
        );

    };


    function propertyFormatter( fetchedProperties: any ) {

      // Why are the keys strings like JSON?
      const chemicalProperties = {
        'Name:': fetchedProperties.Title,
        'Molecular Formula:': molecularFormulaFormatter( fetchedProperties.MolecularFormula ),
        'Weight:': fetchedProperties.MolecularWeight + ' g/mol',
        'Rotatable Bonds:': fetchedProperties.RotatableBondCount.toString(),
        'Chemical Complexity:': fetchedProperties.Complexity.toString(),
        'Stereocenters:': ( fetchedProperties.AtomStereoCount + fetchedProperties.BondStereoCount ).toString(),
        'Chiral Stereocenters:': fetchedProperties.AtomStereoCount.toString(),
        'Geometric Stereocenters:': fetchedProperties.BondStereoCount.toString(),
        'Max Chiral Isomers:': ( 2 ** fetchedProperties.AtomStereoCount ).toString(),
        'Max 3D Conformers': fetchedProperties.ConformerCount3D.toString(),
        'H-Bond Acceptors:': fetchedProperties.HBondAcceptorCount.toString(),
        'H-Bond Donors:': fetchedProperties.HBondDonorCount.toString(),
      };

      return chemicalProperties;

      function molecularFormulaFormatter( formula: string ): string {

        const subscripts = ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉'];
        let formattedFormula = '';
        let currentElement = '';
        let currentCount = '';

        // iterating over entire formula string
        for (let i = 0; i < formula.length; i++) {

          const char = formula.charAt(i);

          if (/[A-Z]/.test(char)) {
            // uppercase letter = new element
            if (currentElement !== '') {

              formattedFormula += currentElement;
              if (currentCount !== '') {

                for (let j = 0; j < currentCount.length; j++) {
                  formattedFormula +=
                  subscripts[parseInt(currentCount.charAt(j))];
                }

              }

            }

            currentElement = char;
            currentCount = '';
          }

          else if (/[a-z]/.test(char)) {
            // lowercase letter is part of current element
            currentElement += char;
          }

          else if (/\d/.test(char)) {
            // digit represents a count
            currentCount += char;
          }

        }

        // handle last element
        formattedFormula += currentElement;
        if (currentCount !== '') {

          for (let j = 0; j < currentCount.length; j++) {
            formattedFormula += subscripts[parseInt(currentCount.charAt(j))];
          }
          
        }

        return formattedFormula;
          
      };

    };

};