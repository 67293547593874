import { SearchBar } from '../../components/search/search-bar';
import { useEffect } from 'react';
import { Logo } from '../../components/logo/logo';
import { MobileSearchBar } from '../../components/search/search-bar--mobile';
import { useResponsiveLayout } from '../../components/hooks/use-responsive-layout';
import { Wrapper } from "../../types";
import { HomeBackground } from './background/home-background.js';
import styles from './home.module.css';

export function Home( { appData, setAppData }: any ): JSX.Element {

  const mobile = useResponsiveLayout( 950, 0 );

  function handleSafariBackgroundColor() {
  
    const black = "#000";

    const lightGreen = "#58AD86";

    const darkGreen = "#3B5A4D"

    if( mobile ) {

      document.body.style.backgroundColor =  lightGreen;

    };

  };

  useEffect( handleSafariBackgroundColor, [ mobile ] );  // this useEffect should be an event handler, i.e. onLoad or onMount?

  return (

    <>

      {
        mobile 
        ?
        < MobileUI mobile={ mobile } appData={ appData } setAppData={ setAppData } />
        :
        < DesktopUI mobile={ mobile } appData={ appData } setAppData={ setAppData } />
      }

      < HomeBackground mobile={ mobile } />
         
    </>

  );

};


function Overlay( { children }: Wrapper ): JSX.Element {

  return (

    < div id="home-overlay" className={ styles.wrapper }>

      < div id="container" className={ styles.container }>{ children }</ div >

    </ div >
    
  );

};


function DesktopUI( { mobile, appData, setAppData }: any ): JSX.Element {

  return (

    < Overlay >

      < ExtraSmallSpacer />

      < Logo

        color={ "light" }

        appData={ appData }

        setAppData={ setAppData }

      />

      {/* < ExtraSmallSpacer /> */}

      < div id="tagline-and-searchbar-wrapper" className={ styles.taglineSearchbarWrapper } >

        < Tagline mobile={ mobile } />

        < SearchBar
          
          appData={ appData }

          setAppData={ setAppData }

        />
        
      </ div >

    </ Overlay>

  );

};


function MobileUI( { mobile, appData, setAppData }: any ): JSX.Element {

  return (

    < Overlay >
            
      < Spacer />

      < Logo

        color={ "light" }

        appData={ appData }

        setAppData={ setAppData }

      />

      < SmallSpacer />

      < SmallSpacer />

      < ExtraSmallSpacer />

      < MobileSearchBar
        
        appData={ appData }

        setAppData={ setAppData }

      />

      < Tagline mobile={ mobile } />

    </ Overlay >

  );

};


function Tagline( { mobile }: any ): JSX.Element {

  return (

    < div id="tagline-wrapper" className={ styles.taglineWrapper } >

      {
        mobile 
        ?
        <></>
        :
        <>

          < h3 id="tagline" className={ styles.tagline } >
            What molecule would you like to learn about?
          </ h3 >

          < h5 id="sub-tagline" className={ styles.subTagline } >
            Learn how 100,000,000 chemical compounds work.
          </ h5 >

        </>
      }


    </ div >

  );

};


function Spacer(): JSX.Element {

  const style = { height: "80px" };

  return < div style={ style } ></ div >;

};


function SmallSpacer(): JSX.Element {

  const style = { height: "50px" };

  return < div style={ style } ></ div >;

};


function ExtraSmallSpacer(): JSX.Element {

  const style = { height: "40px" };

  return < div style={ style } ></ div >;

};





// Pre MobileUI component:

/*
function Tagline(): JSX.Element {

  return (
    < div className={ styles.taglineWrapper } >

      < h3 className={ styles.tagline } >

        { 
          mobile
          ?
          ""
          : 
          "What molecule would you like to learn about?" 
        }
        
      </ h3 >

      { 
        mobile 
        ? 
        <></>
        :
        < h5 className={ styles.subTagline } >
          Learn how 114,000,000+ chemical compounds work.
        </ h5 > 
      }

    </ div >
  );

};
*/

/*
mobile 
? 
<>

  < Spacer />

  < Logo

    color={ "light" }

    appData={ appData }
    setAppData={ setAppData }

  />

  < SmallSpacer />
  < SmallSpacer />

  < MobileSearchBar
    
    appData={ appData }
    setAppData={ setAppData }
    containerRef={ containerRef }
    overlayRef={ overlayRef }

  />

  < Tagline />
</>
:
<>

  < ExtraSmallSpacer />

  < Logo

    color={ "light" }

    appData={ appData }
    setAppData={ setAppData }

  />

  < ExtraSmallSpacer />


  < div className={ styles.taglineSearchbarWrapper } >

    < Tagline />

    < SearchBar
      
      appData={ appData }
      setAppData={ setAppData }

    />
    
  </ div >
</>
*/




// onLoad event handler
/*
function handleLoad() {

  if( mobile ) {

    window.scrollTo(0, 0);

    handleSafariBackgroundColor();


  }

}
*/


// Text Logo: my little molecules
/*
function TextLogo() {

  return (
    <h4 className={ Styles.textLogo } >my little molecules</h4>
  )

}
*/