import { useEffect, useRef, memo } from 'react';
import styles from './chemical-structure.module.css';
//@ts-ignore
import Render from './render.min.js';

// Custom canvas -- an instance of TransformCanvas3D with _AnimatorCanvas methods brought in:
// Move to different file.
const AnimateTransformCanvas3D = function(id: string, width: number, height: number) {

  const p = Math.PI / 15;

  this.timeout = 33;

  this.xIncrement = p;

  this.yIncrement = p;

  this.zIncrement = p;

  this.startAnimation = Render._AnimatorCanvas.prototype.startAnimation;

  this.stopAnimation = Render._AnimatorCanvas.prototype.stopAnimation;

  this.isRunning = Render._AnimatorCanvas.prototype.isRunning;

  this.dblclick = Render.RotatorCanvas3D.prototype.dblclick;

  this.nextFrame = Render.RotatorCanvas3D.prototype.nextFrame;

  this.mousewheel = () => {}; // disables zoom on desktop

  this.gesturechange = () => {}; // disables zoom on mobile

  this.mousedown = function( a: any ) {
    this.lastPoint = a.p
    this.stopAnimation();
  };

  this.mouseup = function( a: any ) {
    this.lastPoint = a.p
    this.startAnimation();
  };
  
  this.create( id, width, height );

};

AnimateTransformCanvas3D.prototype = new Render.TransformCanvas3D;






export const ChemicalStructure = memo(function({

  loadingStructures,

  structures,

  type,

  desktop,

  mobile,

  tablet

}: any ): JSX.Element {
  
  const title = type === "2d" ? "Chemical Line Structure:" : "Molecular Geometry:";

  const canvasContainer = useRef<any>();

  let renderCanvas: any;

  let renderCanvasWidth: number;

  let renderCanvasHeight: number;

  let molecule: any;

  const canvasRef = useRef(null);


  function resizeContainer() {

    if ( canvasContainer.current ) {

      if ( desktop ) {

        canvasContainer.current.style.width = (window.innerHeight * 0.6) + 'px';

        canvasContainer.current.style.height = (window.innerHeight * 0.4) + 'px';

      }

      else if ( tablet ) {

        canvasContainer.current.style.width = 390 + 'px'

        canvasContainer.current.style.height = 390 + 'px'

      } 

      else if (mobile) {

        const size = Math.floor((window.innerWidth * 0.88));

        canvasContainer.current.style.width = size + 'px'

        canvasContainer.current.style.height = size + 'px'

      };

    };

  };
  
  function resizeCanvas() {

    if ( renderCanvas ) {

      if ( desktop ) {

        let _renderCanvasWidth = Math.floor((window.innerHeight * 0.6) * 0.9); // for padding

        let _renderCanvasHeight = Math.floor((window.innerHeight * 0.4) * 0.9); // for padding

        renderCanvas.resize(_renderCanvasWidth, _renderCanvasHeight);

        renderCanvas.repaint();

      }

      else if ( tablet ) {

        renderCanvas.resize(390, 390);

        renderCanvas.repaint();

      }
      
      else if ( mobile ){

        let _renderCanvasWidth = Math.floor(( window.innerWidth * 0.88 ) * 0.9); // for padding

        let _renderCanvasHeight = _renderCanvasWidth

        renderCanvas.resize(_renderCanvasWidth, _renderCanvasHeight);

        renderCanvas.repaint();

      };

    };

  };
  
  function resizeMolecule() {

    // calculate bounding box dimensions
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    // set bounding box dimensions by each atom
    const mol = renderCanvas.getMolecule();

    mol.atoms.forEach((atom: any) => {

      minX = Math.min(minX, atom.x);

      minY = Math.min(minY, atom.y);

      maxX = Math.max(maxX, atom.x);

      maxY = Math.max(maxY, atom.y);

    });

    // calculate scale factor
    const boxWidth = maxX - minX;

    const boxHeight = maxY - minY;

    const scaleFactor = Math.min(

      renderCanvas.width / boxWidth,

      renderCanvas.height / boxHeight

    );


    if (type === '2d') {

      // console.log( "#ATOMS", renderCanvas.molecules[0].atoms.length);//HYDROGENS NOT COUNTED
      if( renderCanvas.molecules[0].atoms.length > 0 && renderCanvas.molecules[0].atoms.length < 5 ) {

        renderCanvas.styles.scale = scaleFactor * 0.35;

        renderCanvas.repaint();

      }

      else if ( renderCanvas.molecules[0].atoms.length >= 5 && renderCanvas.molecules[0].atoms.length < 10 ) {

        renderCanvas.styles.scale = scaleFactor * 0.50;

        renderCanvas.repaint();

      } 
      
      else {

        if (tablet) renderCanvas.styles.scale = scaleFactor * 0.70; // Adjust the 0.8 factor to add padding if needed

        else renderCanvas.styles.scale = scaleFactor * 0.73; // Adjust the 0.8 factor to add padding if needed

        renderCanvas.repaint();

      };

    }

  };
  


  // 1. Sets initial canvas dimensions
  // 2. Instantiates canvas by calling constructors.
  // 3. Set custom styles + removes hydrogens with customizeStyles()
  // 4. Load molecule and handle sizing
  function makeCanvasAndLoadMolecule(): void {
      
    // 1. Set canvas dimensions:
    if ( tablet ) {

      ( renderCanvasWidth = 390 ), ( renderCanvasHeight = 390 );

    } 
    
    else if ( mobile ) {

      renderCanvasWidth = Math.floor( ( window.innerWidth * 0.88 ) * 0.9 );

      renderCanvasHeight = renderCanvasWidth;

    } 
    
    else {

      renderCanvasWidth = Math.floor( ( window.innerHeight * 0.6 ) * 0.9 ); // (window.innerWidth * 0.4) = canvasContainer , * 0.90 for padding

      renderCanvasHeight = Math.floor( ( window.innerHeight * 0.4 ) * 0.9 );

    };


    // 2. Instantiate canvas:
    if( type === "2d" ) {

      if( mobile ) {

        renderCanvas = new Render.ViewerCanvas(
          `display${type}`,
          renderCanvasWidth,
          renderCanvasHeight,
        );

      } 
      
      else {

        renderCanvas = new Render.TransformCanvas(
          `display${type}`,
          renderCanvasWidth,
          renderCanvasHeight,
          false // 3d
        );

        // console.log("2d canvas", renderCanvas);

      };

    } 

    else {

      //@ts-ignore
      renderCanvas = new AnimateTransformCanvas3D(
        "display3d",
        renderCanvasWidth, 
        renderCanvasHeight 
      );

    };

    // 3. Set custom styles + remove hydrogens:
    function customizeStyles( _type: any ) {

      if ( _type === '2d' ) {

        let cs2d = renderCanvas.styles.copy(); 

        cs2d.backgroundColor = "transparent";

        // Color
        cs2d.bonds_splitColor = true;

        cs2d.atoms_useJMOLColors = true;

        cs2d.bonds_colorGradient = true;

        cs2d.atoms_HBlack_2D = false;

        cs2d.atoms_displayTerminalCarbonLabels_2D = true;


        // Size of line structure
        cs2d.atoms_font_size_2D = 16;

        cs2d.bonds_hashWidth_2D = 2;

        cs2d.bonds_width_2D = 1.75;


        // Hide hydrogens
        cs2d.atoms_implicitHydrogens_2D = true;

        return cs2d;

      } 

      else {

        let cs3d = renderCanvas.styles.copy(); 

        cs3d.atoms_useJMOLColors = true;

        cs3d.backgroundColor = "transparent"


        // _Canvas3d:
        cs3d.set3DRepresentation( "Ball and Stick" );

        cs3d.bonds_cylinderDiameter_3D = 3.3;

        cs3d.atoms_useVDWDiameters_3D = true;

        cs3d.atoms_vdwMultiplier_3D = 3.5;
        

        // Color
        cs3d.atoms_useJMOLColors = true;

        cs3d.bonds_color = "#000";


        // if(!mobile) cs3d.bonds_splitColor = true;
        cs3d.bonds_splitColor = true;

        cs3d.atoms_materialShininess_3D = 512;

        cs3d.bonds_materialShininess_3D = 512;

        cs3d.bonds_colorGradient_3D = true;

        return cs3d;

      };

    };

    renderCanvas.styles = customizeStyles(type); 
  
    // 4. Load molecule and handle sizing:
    if ( type === '2d' ) {

      molecule = Render.readMOL( structures.mol2d );

      let HydrogenReducer = new Render.informatics.HydrogenDeducer();

      HydrogenReducer.removeHydrogens( molecule, false );

      molecule.scaleToAverageBondLength(40);

      renderCanvas.loadMolecule( molecule );

      resizeContainer();

      resizeCanvas();

      resizeMolecule();

    } 

    if(type === '3d') {

      molecule = Render.readMOL( structures.mol3d );

      renderCanvas.loadMolecule( molecule );

      ChangeAtomColors( molecule, renderCanvas );

      renderCanvas.timeout = 4;

      renderCanvas.camera.zoomOut();

      if( mobile || tablet ) renderCanvas.camera.zoomOut();

      // if small molecule (total atoms less than 10 (including hydrogens) zoom out more for better fit:  
      if(renderCanvas.molecules[0].atoms.length < 10) {

        renderCanvas.camera.zoomOut();
        renderCanvas.camera.zoomOut();
        renderCanvas.camera.zoomOut();

      }

      renderCanvas.startAnimation();

      renderCanvas.setupScene();

      renderCanvas.repaint();

      resizeContainer();

      resizeCanvas();

      resizeMolecule();

    };

  };
  
  function ChangeAtomColors( molecule: any, TC: any ) {

    // Changing Carbon Color
    let carbonStyle = TC.styles.copy();
    
    carbonStyle.atoms_useJMOLColors = false;

    carbonStyle.atoms_color = '#000'; // blacked out


    // Nitrogen atoms:
    let nitrogenStyle = TC.styles.copy();

    nitrogenStyle.atoms_useJMOLColors = false;

    nitrogenStyle.atoms_color = '#0019A8';


    // Oxygen atoms:
    let oxygenStyle = TC.styles.copy();

    oxygenStyle.atoms_useJMOLColors = false;

    oxygenStyle.atoms_color = 'red';
  

    // Phosphorus atoms:
    let phosphorusStyle = TC.styles.copy();

    phosphorusStyle.atoms_useJMOLColors = false;

    phosphorusStyle.atoms_color = '#944203';
    
    
    // Sulfur atoms:
    let sulfurStyle = TC.styles.copy();

    sulfurStyle.atoms_useJMOLColors = false;

    sulfurStyle.atoms_color = 'yellow';
  

    for(let i = 0; i < molecule.atoms.length; i++) {

      let a = molecule.atoms[ i ];

      if ( a.label === 'C' ) {
        a.styles = carbonStyle;
      }
  
      if ( a.label === 'N' ) {
        a.styles = nitrogenStyle;
      }

      if ( a.label === 'O' ) {
        a.styles = oxygenStyle;
      }
  
      if ( a.label === 'P' ) {
        a.styles = phosphorusStyle;
      }
  
      if ( a.label === 'S' ) {
        a.styles = sulfurStyle;
      }

    };

  };
  
  // Rendering once we have data + setting up handleResize();
  // Need to add a reset pattern here for renderCanvas to avoid potential memory leak
  useEffect( () => {

    // console.log("STRUCTURES: ", structures );

    if ( structures !== undefined && structures[`mol${type}`] ) {

      makeCanvasAndLoadMolecule();

      function handleResize(): void {
        resizeContainer();
        resizeCanvas();
        resizeMolecule();
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };

    }

  }, [ tablet, mobile, structures ]); // why are tablet and mobile dependencies here?


  
  function WebGLCanvas(): JSX.Element {

    if ( structures !== undefined && structures[`mol${type}`] ) {

      return (
        <canvas id={`display${type}`} ref={canvasRef} ></canvas>
      );
      
    } 
    
    else {

      if( structures !== undefined && !structures.mol2d ) return <h3 className={ styles.noStructureData } >{`No ${type} data found. Please confirm spelling.`}</h3>

      else return <h3 className={ styles.noStructureData } >{`No 3-D data to compute :(`}</h3>

    };

  };



  return (

    <div id={`canvasContainer${type}`} style={ { position: "relative" } } className={ styles.canvasContainer } ref={ canvasContainer }>

      <h4 className={ styles.structureTitle } >{ title }</h4>

      { structures === undefined ? < Spinner /> : < WebGLCanvas /> }

    </div>

  );

});





function Spinner(): JSX.Element {

  return (

    <div className={ styles.structureSpinnerContainer }>
      <div className={ styles.structureSpinner }></div>
    </div>

  );

}





















// this.dblclick = function(a:any) {
//   this.center();
//   this.repaint();
//   console.log("dbl click fired");
//   this.stopAnimation();
// };

// override and disable zoom;
// this.multitouchmove = () => {};