
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { AppData } from "../../types";
// React.Dispatch<React.SetStateAction<AppData>>


// add cached-record logic in handleMessage event handler
export function useSearch(

  appData: AppData,

  setChemicalTitle: Function,

  setStructures: Function,

  setOpening: Function,

  setMechanism: Function,

  setToxicity: Function,  

  setProperties: Function,

  setVoice: Function,

  isSaved: boolean | undefined,

  setIsSaved: Function,

  streamed: undefined | boolean,
  
  setStreamed: Function,
  
  setLoading: Function,

  setError: Function,

  setRenderProperties: Function 

): void {
  
  const query = appData.query;

  const socket = useRef< WebSocket | undefined | null >( undefined );

  const [ timeoutId, setTimeoutId ] = useState<NodeJS.Timeout | null>( null );

  const [ reconnectAttempts, setReconnectAttempts ] = useState( 0 );

  const [ shouldReconnect, setShouldReconnect ] = useState< undefined | boolean >( undefined );

  const maxReconnectAttempts = 3;

  const url = "wss://askavagadro.com" // "ws://localhost:3002"
  // const url = "ws://localhost:3002";

  
  useEffect( () => {

    // if query requested while previous query still streaming from server:
    if ( socket.current && socket.current.readyState !== WebSocket.CONNECTING ) destroyStream( socket ); 

    // reset parent component (search-results.tsx) state:
    setChemicalTitle( undefined );

    setStructures( undefined );

    setOpening( [] );

    setMechanism( [] );

    setToxicity( [] );

    setProperties( undefined );

    setIsSaved( undefined );

    setError( undefined );

    setStreamed( undefined );

    setLoading( true );

    setRenderProperties( false );

    // reset local state:
    setReconnectAttempts( 0 );

    setShouldReconnect( false );

    // connect / reconnect
    connect( socket, url, reconnectAttempts, maxReconnectAttempts );

  }, [ appData.submitCount ] )

  
  useEffect( () => {

    if ( shouldReconnect && reconnectAttempts <= maxReconnectAttempts ) {
      reconnect( socket, url );
    }

    else if ( shouldReconnect && reconnectAttempts > maxReconnectAttempts ) {
      setError( "Error connecting to server. Please try again." )
    }

  }, [ reconnectAttempts, shouldReconnect ] );


  // cleaning up our connection timeout on unount:
  useEffect(() => {

    return () => {

      if ( timeoutId !== null ) {

        clearTimeout( timeoutId );

      }

    };

  }, []);
  
  
  function connect( socket: MutableRefObject< WebSocket | undefined | null >, url: string, reconnectAttempts: number, maxReconnectAttempts: number  ) {

    nullify( socket );

    socket.current = new WebSocket( url );


    // Start a connection timeout and store it in state.
    const newTimeoutId = setTimeout(() => {

      // If the socket is still in the CONNECTING state after 3 seconds
      if ( socket.current && socket.current.readyState === WebSocket.CONNECTING ) {

        console.log("Connection attempt timed out. Closing socket.");

        socket.current.close();

      }

    }, 3000 );

    setTimeoutId( newTimeoutId );


    socket.current.onopen = handleOpen;

    socket.current.onmessage = handleMessage;

    socket.current.onerror = handleError;

    socket.current.onclose = handleClose;
    

    function handleOpen() {

      if ( timeoutId !== null ) {

        clearTimeout( timeoutId );
        setTimeoutId( null );

      }
      
      setReconnectAttempts( 0 );

      setShouldReconnect( false );

      setStreamed( false );
  
      socket.current!.send( query );
    
    };

    let dtype: string

    async function handleMessage( message: MessageEvent ) { 
  
      if ( message.data === "end" ) {
  
        socket.current!.close(); 
  
      }
  
      else {
  
        let data: any; 

        try {

          data = await JSON.parse( message.data );

        }

        catch( e: any  ) {

          setError( "Error while parsing data. Please try again" );
          console.log('error parsing payload', e.message);

        }

        if ( data.type === "saved-record" ) {

          dtype = data.type;

          const record = data.content;

          setIsSaved( true );
          // setLoading( false ); // now set inside chemical-description renderSavedDescription.

          setChemicalTitle( record.chemicalTitle );

          setStructures( record.structures );

          setOpening( record.description.opening );

          setMechanism( record.description.mechanism );  

          setToxicity( record.description.toxicity );  

          setProperties( record.properties );

          if( record.voice ) setVoice( record.voice );
          else setVoice( null )

        }

        else {

          setIsSaved( false );

          setLoading( false );

          if ( data.type === "chemical-title" ) setChemicalTitle( data.content )
  
          else if ( data.type === "structures" ) setStructures( data.content )
  
          else if ( data.type === "opening" ) setOpening( ( prev: string[] ) => [ ...prev, data.content ] );
  
          else if ( data.type === "mechanism" ) setMechanism( ( prev: string[] ) => [ ...prev, data.content ] );
  
          else if ( data.type === "toxicity" ) setToxicity( ( prev: string[] ) => [ ...prev, data.content ] );
  
          else if ( data.type === "properties" ) {
            setProperties( data.content );
            setRenderProperties( true );
          }

          // else if ( data.type === "voice" ) setVoice( data.content );

        };

  
      };

    };
  
    
    function handleError( errorEvent: Event ) {
        
      nullify( socket ); // error event is fired is socket is closed due to an error, this means the close event is fired too.
    
    };
    
    
    function handleClose( event: CloseEvent ) {

      if ( timeoutId !== null ) {

        clearTimeout( timeoutId );
        setTimeoutId( null );

      }

      if ( !event.wasClean ) {

        setShouldReconnect( true );

        setReconnectAttempts( ( prev ) => prev + 1 );

      } 
      
      else if ( event.wasClean && dtype !== "saved-record" ) {

        setStreamed( true );

      }
      
    };
    
  };



  function reconnect( socket: MutableRefObject< WebSocket | undefined | null >, url: string ) {

    console.log( `Connection to server unsuccessful. Attempting to re-connect. Re-connection attempts: ${ reconnectAttempts }` );

    nullify( socket );

    connect( socket, url, reconnectAttempts, maxReconnectAttempts );

  };



  function nullify( socket: MutableRefObject< WebSocket | undefined | null >  ) {

    if ( socket.current !== null ) {

      socket.current = null;
    
    };

  };


  function destroyStream( socket: MutableRefObject< WebSocket | undefined | null > ) {

    const streaming = !streamed;

    if ( appData.submitCount > 0 && socket.current && streaming ) {

      if( socket.current.readyState !== WebSocket.CLOSED ) socket.current.send( "destroy-stream" ); // shall we merge this nested conditional check with the higher level one?

    };

  };

};








  
// if ( !event.wasClean && reconnectAttempts < maxReconnectAttempts ) {

//   setReconnectAttempts( (prev) => prev + 1 );

//   reconnect( socket, url );

// }

// if saved record, streamed is set to true at the end of renderSavedRecord inside of ChemicalDescription
// else streamed is set to true here, below:
// if( event.wasClean && dtype !== "saved-record" ) {

//   setStreamed( true ); 

// };






// return () => {
//   if ( socket.current ) socket.current.close(); // clean up the socket if user goes home and unmounts search-results
// }
