
import logo from '../../assets/img/logo.png';
import logoDark from '../../assets/img/logo--dark.png';
import logoGray from '../../assets/img/logo--gray.png';
import styles from './logo.module.css'
import { AppData } from "../../types"

export function Logo( { color, appData, setAppData }: any ) {

  let logoType = ( color === "dark" ? logoDark : logo );

  if ( color === "gray" ) logoType = logoGray;
  
  return (

    <div className={ appData.page === "home" ? styles.homeLogoContainer : styles.resultsLogoContainer } >

      <img

        className= { appData.page === "home" ? styles.homeLogo : styles.resultsLogo } 
        
        src={ logoType } 

        onClick={ appData.page === "home" ? ()=>null : () => {
          setAppData( () => resetAppData() )
        }}

      />

    </div>

  );
  
};


function resetAppData(): AppData {

  return {
    page: "home",
    submitCount: 0,
    rawQuery: "",
    query: "",
    voiceOn: false,
  };

};




