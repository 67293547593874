import { useEffect, useRef, useState } from "react";
import { AppData } from "../../types";
import chemicalNames from "./chemical-names";
import styles from "./search-bar.module.css";



export function SearchBar( { appData, setAppData }: any ) {

  const { page } = appData;

  const [ suggestionsVisible, setSuggestionsVisible ] = useState( false );

  const [ shouldSubmit, setShouldSubmit ] = useState( false );

  const [ activeSuggestionIndex, setActiveSuggestionIndex ] = useState( -1 );

  const [ hoveredSuggestionIndex, setHoveredSuggestionIndex ] = useState( -1 );

  const [ searchInput, setSearchInput ] = useState( appData.rawQuery );

  const containerRef = useRef< HTMLDivElement>( null );

  const filteredSuggestions = chemicalNames.filter( ( suggestion: string ) => suggestion.toLowerCase().includes( searchInput.toLowerCase() ) );   // auto suggest:


  function handleInputChange( e: any ): void {

    const inputValue = e.target.value;

    setSearchInput( inputValue );

    if ( inputValue === "" ) {

      setSuggestionsVisible( false );

      return;

    };
    
    setSuggestionsVisible( true );

  };


  function handleSuggestionClick( suggestion: string ): void {

    setSearchInput( suggestion );

    setSuggestionsVisible( false );

    setShouldSubmit( true );

  };


  function handleSubmit(): void {

    if ( searchInput.length > 0 ) {

      setSuggestionsVisible( false );

      setShouldSubmit( true );

    };

  };


  function handleKeyDown( e: any ): void {

    if ( e.key === "ArrowDown" ) {

      e.preventDefault(); // prevent page scrolling

      setActiveSuggestionIndex( ( prevIndex ) => Math.min( prevIndex + 1, filteredSuggestions.length - 1 ) );

    } 
    
    else if ( e.key === "ArrowUp" ) {

      e.preventDefault();

      setActiveSuggestionIndex( ( prevIndex ) => Math.max( prevIndex - 1, 0 ) );

    } 
    
    else if ( e.key === "Enter" && activeSuggestionIndex >= 0 ) {

      e.preventDefault();

      handleSuggestionClick( filteredSuggestions[ activeSuggestionIndex ] );

    } 
    
    else if ( e.key === "Enter" ) {

      e.preventDefault();

      handleSubmit();

    }

  };



  const suggestions = filteredSuggestions.map( ( suggestion, index ) => (
    <div

      key={ suggestion }

      onMouseEnter={ () => { setHoveredSuggestionIndex( index ); setActiveSuggestionIndex(-1); } }

      onMouseLeave={ () => setHoveredSuggestionIndex( -1 ) }

      className={`

        ${ styles.suggestion }

        ${ index === hoveredSuggestionIndex || index === activeSuggestionIndex ?  styles.activeSuggestion : "" }

        ${ page === "home" ? styles.whiteColor : styles.darkGrayColor }

      `}

      onClick={(e) => {

        handleSuggestionClick( suggestion );

        e.stopPropagation(); // prevents click event from bubbling up to handleGloblalClick handler which closes the suggestionBox

      }}

    >
      <i

        className={`

          fa 

          fa-search 

          ${ styles.faIcons }

        `}

      ></i>

      <p>
        { suggestion }
      </p>

    </div>
  ));



  // This can likely be re-written, pulled out of a useEffect and into an event handler on JSX.
  useEffect(() => {

    function handleClickOutside( event: Event ) {

      if ( !containerRef.current?.contains( event.target as Node ) && suggestionsVisible ) setSuggestionsVisible( false ); // hide suggestions if mousedown event does occurs outside of the search-bar and suggestions

    };

    document.addEventListener( "mousedown", handleClickOutside );

    return () => {
      document.removeEventListener( "mousedown", handleClickOutside );
    };

  }, [ suggestionsVisible ] );



  // This can likely be re-written, pulled out of a useEffect and into an onSubmit event handler on JSX.
  useEffect(() => {

    if ( shouldSubmit ) {

      submit();

      setShouldSubmit( false );
      
    }

    async function submit() {
  
      setAppData( ( appData: AppData ) => {
  
        return {
          ...appData,
          page: "search-results",
          submitCount: appData.submitCount + 1,
          rawQuery: searchInput,
          query: searchInput.trim().toLowerCase()
        };
  
      });
  
    };

  }, [ searchInput, shouldSubmit ] );



  return (
    
    < div id="searchbar-container" ref={ containerRef } className={ styles.searchbarContainer }>

      < form

        id="search-form"

        className={`

          ${ styles.searchForm }

          ${ suggestionsVisible ? styles.focusedBorders : styles.blurredBorders }

          ${ page === "home" ? styles.tintedGlass : styles.darkerTintedGlass }

          ${ page === "home" ? styles.whiteColor : styles.darkGrayColor }

        `}

      >
        < i

          className={`

            fa 

            fa-search

            ${ styles.faIcons }

          `}

        ></ i >

        < input

          type="text"

          id="input-field"

          className={`

            ${ styles.searchInput }

            ${ page === "home" ? styles.whiteColor : styles.darkGrayColor }

          `}

          placeholder={
            page === "home"
            ? 
            "Try Morphine, Paxlovid, Vitamin C, Tylenol, Adrenaline, TNT, LSD, Sugar, Crack, etc..."
            :
            "Search"
          }

          onChange={ handleInputChange }

          onKeyDown={ handleKeyDown }

          onFocus={ () => setSuggestionsVisible( true ) }

          autoComplete="off"

          spellCheck="false"

          value={ searchInput }
          
        />

        < i

          className={`

            fa 

            fa-arrow-right

            ${ styles.faIcons }

          `}

          onClick={(e: any) => {

            e.preventDefault();

            handleSubmit();

          }}

        ></ i >

      </ form >

      < div

        id="suggestions-container"

        className={`

          ${ styles.suggestionsContainer }

          ${ suggestionsVisible ? styles.visible : styles.hidden }

          ${ page === "home" ? styles.tintedGlass : styles.darkestTintedGlass }

        `}

        onClick={ (e) => e.stopPropagation() }

      >

        { suggestions }

      </ div >

    </ div >


  );

}
























// < div  className={ styles.searchbarWrapper }>
// </ div >







// I dont think globalClickRef is needed anymore. We seem to be handling the global click suggestions = false by using an event listener on the document. What is this div even doing? */}
/* 
  < div

    ref={ globalClickRef }

    className={ styles.globalClickWrapper }

  >
  </ div > 
*/



// attempt to make height of suggestions-container responsive to remaining vertical viewport height:
/*
  function calculateRemainingViewportHeight() {

    if ( suggestionsContainerRef.current ) {

      const elementRect = suggestionsContainerRef.current.getBoundingClientRect();

      const elementBottom = elementRect.bottom;

      const viewportHeight = window.innerHeight;
      
      const remainingHeight = viewportHeight - elementBottom;
      
      console.log(`Remaining viewport height underneath the element: ${remainingHeight}px`);

    //   if( remainingHeight < 0 ) {
        
    //     suggestionsContainerRef.current.style.height = (300 + remainingHeight * 1.5 ).toString() + "px";

    //   }

    //   else {

    //     suggestionsContainerRef.current.style.height = "300px"

    //   }
      

    //   console.log( suggestionsContainerRef.current.style.height );

    };

  };
*/







// style attribute of suggestions-container:
/*
style={

  appData.page === "home"
    ? 
    {
      background: "rgba(0, 0, 0, 0.2)",
      border: "3px solid #303134",
      color: "#ffffff",

    }
    :
    {
      background: "rgba(0, 0, 0, 0.90)",
      border: "3px solid rgba( 0, 0, 0, 0.2 )",
      color: "rgba( 255, 255, 255, 0.5 )",
      // border: "3px solid #303134",

      // border: "2px solid rgba( 0, 0, 0, 0.2 )",
    }

}
*/





// unused styles for frosted glass:
// From suggestionsContainer:
/*


background: "rgba(255, 255, 255, 0.05)",
background: "rgba(255, 255, 255, 0.02)",
border: "3px solid rgba( 255, 255, 255, 0.30 )",
border: "1.5px solid rgba( 255, 255, 255, 0.60 )",

*/

// From searchForm:
/*

background: "rgba(255, 255, 255, 0.05)",

background: "rgba(255, 255, 255, 0.02)",



border: "3px solid rgba( 255, 255, 255, 0.30 )",

border: "3px solid rgba( 0, 0, 0, 0.90 )",

border: "3px solid rgba( 255, 255, 255, 0.1 )",

*/

















// auto-focus SearchBar on load -- turned off
/*

const searchInputRef = useRef< HTMLInputElement >( null );  // used to focus searchInput on load so user can type immediately.

// on input field:
ref={ searchInputRef }


useEffect(() => {

  /*
  if (!mobile) {

    if (searchInputRef.current) {

      // searchInputRef.current.focus();

    }

  }
  
  const handleGlobalClick = () => setSuggestionsVisible(false);

  // document.addEventListener('click', handleGlobalClick);

  return () => document.removeEventListener('click', handleGlobalClick);

}, [ searchInputRef ]);



*/
