// @ts-nocheck
import { Suspense, useState } from "react"
import { BackgroundMolecule } from "./background-molecule"

import styles from "./background-molecule.module.css"
// Comment the above and uncomment the following to import the WebGL BG lazily for faster loading times
// const BackgroundMolecules = lazy(() => import('./BackgroundMolecules'))

export function HomeBackground( { mobile } ): JSX.Element {

  const [ speed, _ ] = useState( 0.002 );
  

  return (
    <>

      < Suspense fallback={ null } >

        < BackgroundMolecule speed={ speed } mobile={ mobile } />

        { ( window.innerWidth < 950 ) ? <div className={ styles.fadeIn }></div> : <></> }
        
      </ Suspense >

    </>

  );

};

