import { useEffect, useRef } from "react";
import { AppData } from "../../../types";

// this file smells. should be refactored.
export function useVoice( appData: AppData, setAppData: Function, voicePath: string | undefined | null ): void {

  const { voiceOn } = appData;

  const audioPlayer = useRef< HTMLAudioElement | null >( new Audio() );

  if( audioPlayer.current ) audioPlayer.current.addEventListener('ended', handleEnd );


  // Setting path of voice:
  useEffect(() => {

    if( voicePath && audioPlayer.current ) audioPlayer.current.src = voicePath

    else if( !voicePath && audioPlayer.current ) audioPlayer.current!.src = ""

  }, [ voicePath ]);


  // Controlling voice via speaker button: 
  useEffect(() => {

    if( voicePath && voiceOn && audioPlayer.current ) {

      audioPlayer.current.play();

    }


    else if ( !voiceOn && audioPlayer.current ) {

      audioPlayer.current.pause();

    };


    return () => {

      if( audioPlayer.current ) {

        audioPlayer.current.pause();

        // audioPlayer.current.removeEventListener( "ended", onEnd )

        // audioPlayer.current = null;
      }

    };

  }, [ voicePath, voiceOn ] );



  function handleEnd() {

    setAppData( (prev: any) => {

      return {

       ...prev,
       
       voiceOn: false

      }

    });
    
  };
  
};























// Is play vocie at start still being used?
/*
const isPlayedAtStart = useRef(false);

function playVoiceAtStart(): void {
  if( voicePath && appData.voiceOn && audioPlayer.current && !isPlayedAtStart.current ) {
    audioPlayer.current.play();
    // prevent audio from restarting on scroll event:
    isPlayedAtStart.current = true; 
  };
};

useEffect(() => {
  window.addEventListener('scroll', playVoiceAtStart);
  // window.addEventListener('touchmove', playVoiceAtStart);
  window.addEventListener('touchstart', playVoiceAtStart);
  window.addEventListener('mousedown', playVoiceAtStart);

  return () => {
    window.removeEventListener('scroll', playVoiceAtStart);
    window.removeEventListener('touchstart', playVoiceAtStart);
    window.removeEventListener('mousedown', playVoiceAtStart);
  };
}, [ voicePath ]);
*/





/*
function onEnd() {
  if (voiceStackIndex === 0 ) {
    setTimeout( () => {
      setVoiceStackIndex( ( prev: number ) => prev + 1 );
    }, 1000 )
  } else {
    setVoiceStackIndex( ( prev: number ) => prev + 1 )
  };
  // if (voiceStackIndex === 1) {
  //   ToggleHidden( additionalInfoButton );
  // };
};
*/





/*
function PlayVoice( 
  voiceOn: boolean,
  voicePath: string, 
  path: string, 
  voiceStackIndex: number, 
  setVoiceStackIndex: Function, 
  additionalInfoButton: any, 
  additionalInfo: boolean ): void {

  const audioPlayer = new Audio();

  // needs to be refactored. These variable are being set twice.
  const additionalInfoVoice = '/src/voices/additionalInfo.mp3';
  const furtherDataVoice = "/src/voices/furtherData.mp3";

  if( voiceStackIndex === 0 ) audioPlayer.src = voicePath;
  if( voiceStackIndex === 1 ) audioPlayer.src = additionalInfoVoice;
  if( voiceStackIndex === 2 && additionalInfo ) audioPlayer.src = furtherDataVoice;

  if( voiceOn ){
    audioPlayer.play();    
  } else {
    audioPlayer.pause();    
  }

  function onEnd() {
    if (voiceStackIndex === 0 ) {
      setTimeout( () => {
        setVoiceStackIndex( ( prev: number ) => prev + 1 );
      }, 1000 )
    } else {
      setVoiceStackIndex( ( prev: number ) => prev + 1 )
    };
    if (voiceStackIndex === 1) {
      ToggleHidden( additionalInfoButton );
    };
  };

  audioPlayer.addEventListener('ended', onEnd );
};
*/

// function ToggleHidden( domNode: any ) {
//     domNode.current.classList.toggle( Styles.hidden );
// };






// useEffect( () => {

//   console.log("RERUNNING EFFECT");

//   if( voiceStackIndex === 0 ) audioPlayer.src = voicePath;
//   if( voiceStackIndex === 1 ) audioPlayer.src = additionalInfoVoice;
//   if( voiceStackIndex === 2 && additionalInfo ) audioPlayer.src = furtherDataVoice;

//   if( voicePath && voiceOn ){
//     console.log("RERUNNING IF 1");
//     audioPlayer.play();    
//     console.log("audioPlayerObj after play", audioPlayer);

//   } 
//   else if( voicePath && !voiceOn ) {
//     console.log("RERUNNING IF 2");
//     audioPlayer.pause();
//     console.log("audioPlayerObj after pause", audioPlayer);
//   }

//   // if ( voicePath && voiceOn || !voiceOn ) {
//   //   // voice stack and voice stack index need to be refactored
//   //   // PlayVoice( voiceOn, voicePath, voiceStack[ voiceStackIndex ], voiceStackIndex, setVoiceStackIndex, domNodes, additionalInfo );
//   // };
// }, [ voiceOn, voiceStackIndex, appData.searchResults.description, additionalInfo ] );

// useEffect(() => {
//   console.log("RERUNNING EFFECT - SRC CHANGE");

//   if (voiceStackIndex === 0) audioPlayer.src = voicePath;
//   if (voiceStackIndex === 1) audioPlayer.src = additionalInfoVoice;
//   if (voiceStackIndex === 2 && additionalInfo) audioPlayer.src = furtherDataVoice;

// }, [voiceStackIndex, voicePath, additionalInfoVoice, furtherDataVoice]);
