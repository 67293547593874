import { useState } from "react";
import { Logo } from "../../components/logo/logo";
import { SearchBar } from "../../components/search/search-bar";
import { toTitleCase } from "../../utils/to-title-case";
import { MobileSearchBar } from "../../components/search/search-bar--mobile";
import { ChemicalDescription } from "./chemical-description";
import { ChemicalStructure } from "./chemical-structure";
import { ChemicalProperties } from "./chemical-properties";
import { useVoice } from "./hooks/use-voice";
import { useDomNodes } from "./hooks/use-dom-nodes";
import { useResponsiveLayout } from "../../components/hooks/use-responsive-layout";
import { AppData, Structures, Properties } from "../../types";
import { useSearch } from "../../components/search/use-search";
import styles from "./search-results.module.css";



export function SearchResults( { appData, setAppData }: any ): JSX.Element {

  const [ chemicalTitle, setChemicalTitle ] = useState< undefined | string >( undefined );

  const [ structures, setStructures ] = useState< undefined | Structures >( undefined );

  const [ opening, setOpening ] = useState< string[] >( [] );

  const [ mechanism, setMechanism ] = useState< string[] >( [] );

  const [ toxicity, setToxicity ] = useState< string[] >( [] );

  const [ properties, setProperties ] = useState< undefined | Properties >( undefined );

  const [ voice, setVoice ] = useState< undefined | string | null  >( undefined );

  const [ isSaved, setIsSaved ] = useState< undefined | boolean >( undefined );

  const [ error, setError ] = useState< undefined | string | null >( undefined );

  const [ streamed, setStreamed ] = useState< undefined | boolean >( undefined );

  const [ loading, setLoading ] = useState< undefined | boolean >( undefined );

  const [ renderProperties, setRenderProperties ] = useState< undefined | boolean >( undefined )

  const domNodes = useDomNodes(); // truncate and re-factor.

  const mobile = useResponsiveLayout( 950, 0 );

  if ( mobile ) document.body.style.backgroundColor = '#141414'; // safari--mobile body background-color issue.

  const tablet = useResponsiveLayout( 1350, 951 );

  const desktop = !tablet && !mobile;

  useVoice(

    appData,

    setAppData,

    voice 

  );

  useSearch( 

    appData,

    setChemicalTitle, 

    setStructures, 

    setOpening,

    setMechanism,

    setToxicity,

    setProperties,

    setVoice,
    
    isSaved,

    setIsSaved,

    streamed,
    
    setStreamed,

    setLoading,

    setError,

    setRenderProperties

  );


  function handleInitialLoad() {

    if ( mobile ) document.body.style.backgroundColor = '#141414'; // safari--mobile body background-color issue.

  };


  return (

    < div onLoad={ handleInitialLoad } id="search-results" className={ styles.pageWrapper } >

      {
        desktop 
        ? 
        null 
        : 
        < LogoAndSearchBar

          type={ "header" }
          mobile={ mobile }

          appData={ appData }
          setAppData={ setAppData }

        />

      }

      < div id="structures" className={ styles.structures } >

        < ChemicalStructure

          structures={ structures }
          type={ "2d"}

          desktop={ desktop }
          mobile={ mobile }
          tablet={ tablet }

        />

        < ChemicalStructure

          structures={ structures }
          type={ "3d" }
        
          desktop={ desktop }
          mobile={ mobile }
          tablet={ tablet }

        />

      </ div >

      < div id="summary" className={ styles.summary } >

        < div id="summary-header" className={ styles.head } >

          {
            desktop 
            ? 
            < LogoAndSearchBar

              type={ "not-header" }
              mobile={ mobile }

              appData={ appData }
              setAppData={ setAppData }
              
            />
            : 
            < RecordTitle appData={ appData } chemicalTitle={ chemicalTitle } domNodes={ domNodes } />
          }

          {
            voice
            ? 
            < VoiceIcon voiceOn={ appData.voiceOn } setAppData={ setAppData } />
            : 
            null
          }

        </ div >

        < div id={ "summary-body" } className={ styles.scrollWrapper } >

          {
            desktop 
            ? 
            < RecordTitle appData={ appData } chemicalTitle={ chemicalTitle } domNodes={ domNodes } />
            : 
            null
          }

          {
            error 
            ?
            < RetryQuery 

              appData={ appData }
              setAppData={ setAppData }

              error={ error }
              setError={ setError }
      
            />
            : 
            <>
              < ChemicalDescription

                isSaved={ isSaved }

                opening={ opening }
                mechanism={ mechanism }
                toxicity={ toxicity }

                streamed={ streamed }

                loading={ loading } 
                setLoading={ setLoading }

                domNodes={ domNodes }

                setRenderProperties={ setRenderProperties }

              />

              < ChemicalProperties

                isSaved={ isSaved }

                properties={ properties }

                streamed={ streamed }

                loading={ loading } 

                domNodes={ domNodes }

                renderProperties={ renderProperties }

                setStreamed={ setStreamed }

              />
            </>
            
          }

        </ div >

      </ div >

    </ div >
  );

}



function RetryQuery({ error, setError, appData, setAppData }: any ): JSX.Element {

  return (
    <>

      < p style={{ fontFamily: "Arial" }}>{ error }</ p >

      < button
        style={{ width: "100px", cursor: "pointer" }}
        onClick={() => {

          setError( null );
          setAppData( ( prev: AppData ) => { return { ...prev, submitCount: prev.submitCount + 1 } } ) // triggers useSearch again.
          
        }}
      >
        Try again
      </ button >

    </>
  );

};



function RecordTitle( { appData, chemicalTitle, domNodes }: any): JSX.Element {

  let sameTitle: boolean | undefined;

  let recordTitle;

  if ( appData.query && chemicalTitle ) sameTitle = appData.query === chemicalTitle.toLowerCase() // these .toLowerCase() calls might be unnecessary. query should already be lowerCase.

  else sameTitle = undefined; 


  if( sameTitle ) recordTitle = appData.rawQuery;

  else if ( !sameTitle && appData.query && chemicalTitle ) recordTitle = appData.rawQuery + " / " + toTitleCase( chemicalTitle )

  else recordTitle = appData.rawQuery; // I dont understand the use of this else case.
  
  return (

    < div id='record-title' className={ styles.recordTitle } ref={ domNodes.descriptions.recordTitle } >

      { appData.query ? recordTitle : "" }

    </ div >

  );
  
};



function LogoAndSearchBar( { appData, setAppData, mobile, type }: any ): JSX.Element {

  return (

    < div className={ type === "header" ? styles.topHead : styles.head } >

      < Logo

        color={ type === "header" ? "light" : "gray" }

        appData={ appData }
        setAppData={ setAppData }

      />

      {

        mobile 
        ?
        < MobileSearchBar
          
          appData={ appData }
          setAppData={ setAppData }

        />
        : 
        < SearchBar
          
          appData={ appData }
          setAppData={ setAppData }

        />

      }

    </ div >

  );

};



function VoiceIcon( { voiceOn, setAppData }: any ): JSX.Element {

  function handleClick(): void {

    setAppData((prevData: AppData) => {

      return {
        ...prevData,
        voiceOn: !prevData.voiceOn,
      };

    });

  };

  return (

    < div id="head-icons" className={ styles.icons } >
      <i
        className={`fa-solid fa-volume-${ voiceOn ? "high" : "off" } ${ styles.icon }`}
        onClick={ handleClick }
      ></i>
      {/* <i className={`fa-regular fa-bookmark ${ styles.icon }`}></i> */}
      {/* <i className={`fa-regular fa-share-from-square ${ styles.icon `}></i> */}
    </ div >

  );

};






