// File Description:
/**
 * 
 * Takes a string and a ref to a DOM element -- iterates over string and appends each character 
 * to the DOM element using a recursive call via setInterval.
 * 
 */
export async function stream( text: string, domNode: any, type: string, speed: number = 10 ) {

  function getRandomNumber(): number {
    return Math.floor(Math.random() * 5) + 1;
  };

  function getRandomSpeedBetween60And120(): number {
    return Math.floor(Math.random() * (120 - 60 + 1)) + 60;
  };


  return new Promise( (resolve, reject) => {

    let i = 0;

    let streamType: number;
    if ( type === "single" ) streamType = 1
    if ( type === "quad" ) streamType = 4
    
    const appendCharacter = setInterval( () => {
      
      if( type === "random" ) streamType = getRandomNumber();

      try {

        if( streamType === 1 ) {          
          domNode.current.textContent += text[i]  
          i++;
        };

        if( streamType === 2 ) {
          if(i !== text.length - 1 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ];  
          } else {
              domNode.current.textContent += text[i];
          };
          i = i+2;
        };

        if ( streamType === 3 ) {
          if(i < text.length - 2 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ] + text[ i + 2 ];
          } else if( i < text.length - 1 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ];
          } else {
              domNode.current.textContent += text[i];
          };
          i = i+3;
        };

        if( streamType === 4 ){
          if(i < text.length - 3 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ] + text[ i + 2 ] + text[ i + 3 ];
          } else if( i < text.length - 2 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ] + text[ i + 2 ];
          } else if( i < text.length - 1 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ];
          } else {
              domNode.current.textContent += text[i];
          };
          i = i+4;
        };

        if ( streamType === 5 ) {
          if(i < text.length - 4 ) {
            domNode.current.textContent += text[i] + text[ i + 1 ] + text[ i + 2 ] + text[ i + 3 ] + text[ i + 4 ];
          } else if( i < text.length - 3 ) {
            domNode.current.textContent += text[i] + text[ i + 1 ] + text[ i + 2 ] + text[ i + 3 ];
          } else if( i < text.length - 2 ) {
            domNode.current.textContent += text[i] + text[ i + 1 ] + text[ i + 2 ];
          } else if( i < text.length - 1 ) {
              domNode.current.textContent += text[i] + text[ i + 1 ];
          } else {
            domNode.current.textContent += text[i];
          };
          i = i+5;
        };

        if( i >= text.length ) {
          resolve("done");
          clearInterval( appendCharacter );
        };
        
      } 
      
      catch(e) {
        clearInterval( appendCharacter );
        reject(e)
      }

      }, speed );
    }
  );
};


// Character Append Logic as Case-Switch:
/*
switch(streamType) {
  case "single":
    domNode.current.textContent += text[i];
    i++;
    break;

  case "double":
    if(i !== text.length - 1) {
      domNode.current.textContent += text[i] + text[i + 1];
    } else {
      domNode.current.textContent += text[i];
    }
    i += 2;
    break;

  case "triple":
    if(i < text.length - 2) {
      domNode.current.textContent += text[i] + text[i + 1] + text[i + 2];
    } else if(i < text.length - 1) {
      domNode.current.textContent += text[i] + text[i + 1];
    } else {
      domNode.current.textContent += text[i];
    }
    i += 3;
    break;

  case "quadruple":
    if(i < text.length - 3) {
      domNode.current.textContent += text[i] + text[i + 1] + text[i + 2] + text[i + 3];
    } else if(i < text.length - 2) {
      domNode.current.textContent += text[i] + text[i + 1] + text[i + 2];
    } else if(i < text.length - 1) {
      domNode.current.textContent += text[i] + text[i + 1];
    } else {
      domNode.current.textContent += text[i];
    }
    i += 4;
    break;

  case "quintuple":
    if(i < text.length - 4) {
      domNode.current.textContent += text[i] + text[i + 1] + text[i + 2] + text[i + 3] + text[i + 4];
    } else if(i < text.length - 3) {
      domNode.current.textContent += text[i] + text[i + 1] + text[i + 2] + text[i + 3];
    } else if(i < text.length - 2) {
      domNode.current.textContent += text[i] + text[i + 1] + text[i + 2];
    } else if(i < text.length - 1) {
      domNode.current.textContent += text[i] + text[i + 1];
    } else {
      domNode.current.textContent += text[i];
    }
    i += 5;
    break;

  default:
    if(i >= text.length) {
      resolve("done");
      clearInterval(appendCharacter);
    }
    break;
}
*/