import { AppData } from "../../types";
import { useEffect, useRef, useState } from 'react';
import styles from './search-bar--mobile.module.css';
import chemicalNames from './chemical-names';

function setThemeColor( color: string ): void {

  const themeColor = document.querySelector('meta[name="theme-color"]');

  if (themeColor) themeColor.setAttribute("content", color);

};


export function MobileSearchBar( { appData, setAppData }: any ) {

  const { page } = appData;

  const [ suggestionsVisible, setSuggestionsVisible ] = useState( false );

  const [ shouldSubmit, setShouldSubmit ] = useState( false ) ;

  const [ activeSuggestionIndex, setActiveSuggestionIndex ] = useState( -1 );

  const [ hoveredSuggestionIndex, setHoveredSuggestionIndex ] = useState( -1 );

  const [ searchInput, setSearchInput ] = useState( appData.rawQuery );

  const [ focused, setFocus ] = useState( false );


  const searchInputRef = useRef< HTMLInputElement >( null );


  
  // const [ fakeInputVisible, setFakeInputVisible ] = useState<boolean>( true );

  // const [ isReadOnly, setIsReadOnly ] = useState<boolean>( true );





  const filteredChemicalNames = chemicalNames.filter( ( chemicalName ) => chemicalName.toLowerCase().includes( searchInput.toLowerCase() ) ); // auto-suggest:

  const lightGreen = "#58AD86";

  const lightGreenRGBA = "rgba( 88, 173, 134, 1 )";


  function handleFocus( e: any ) {

    // e.preventDefault();

    
    setFocus( true );
    
    setSuggestionsVisible( true );

    // setFakeInputVisible( false );


    const darkerTintedGlass = "#0a0a0a";


    if ( page === "search-results" ) {

      setThemeColor( darkerTintedGlass );

      document.body.style.overflow = "hidden";

    };

  };


  function handleBlur() {

    setFocus( false );

    setSuggestionsVisible( false );

    // setFakeInputVisible( true );


    // setIsReadOnly( true );

    if ( page === "search-results" ) {

      setThemeColor( "#141414" );

      document.body.style.overflow = "auto";

    }

    else document.body.style.background = lightGreen;

  };


  function handleInputChange(e: any) {

    const inputValue = e.target.value;

    setSearchInput( inputValue );

  };


  // This kind of smells. Why are handleSuggestionClick and handleSubmit so similar? It feels like they do the same thing and can be combined.
  function handleSuggestionClick( suggestion: string ) {

    if ( page === "search-results" ) {

      setThemeColor( "#141414" );

      document.body.style.overflow = "auto";

    };

    setFocus( false );

    setSuggestionsVisible( false );

    setSearchInput( suggestion );

    setShouldSubmit( true );

  };


  function handleSubmit() {

    if ( page === "search-results" ) {

      setThemeColor( "#141414" );

      document.body.style.overflow = "auto";

    };

    setFocus( false );

    setSuggestionsVisible( false );

    setShouldSubmit( true );

  };


  function handleKeyDown(e: any) {

    if ( e.key === "ArrowDown" ) {

      e.preventDefault(); // prevents page scrolling

      setActiveSuggestionIndex( ( prevIndex ) => Math.min( prevIndex + 1, filteredChemicalNames.length - 1 ) );

    } 
    
    else if ( e.key === "ArrowUp" ) {

      e.preventDefault();

      setActiveSuggestionIndex( ( prevIndex ) => Math.max( prevIndex - 1, 0 ));

    } 
    
    else if ( e.key === "Enter" && activeSuggestionIndex >= 0) {
      
      e.preventDefault();

      handleSuggestionClick( filteredChemicalNames[ activeSuggestionIndex ] );

    } 
    
    else if ( e.key === "Enter" ) {

      e.preventDefault();

      handleSubmit();

    };

  };


  // is the loop running on every re-render? Is that necessary? Most likely because we need new suggestions on each new keystroke 
  let suggestions = []
  for( let i = 0; i < filteredChemicalNames.length; i++ ) {

    const suggestion = filteredChemicalNames[ i ];

    suggestions.push(
      
      <div

        key={ suggestion }

        onMouseEnter={ () => { setHoveredSuggestionIndex( i ); setActiveSuggestionIndex( -1 ); } }

        onMouseLeave={ () => setHoveredSuggestionIndex( -1 ) }

        className={`
          
          ${ styles.suggestion }

          ${ i === hoveredSuggestionIndex || i === activeSuggestionIndex ? styles.activeSuggestion : "" }

          ${ page === "home" ? styles.whiteColor : styles.darkGrayColor }

        `}

        onClick={ () => handleSuggestionClick( suggestion ) }

      >

        <i

          className={`
            fa 
            fa-search 
            ${ styles.faIcons }
          `}

        >
        </i>

        <p>
          { suggestion }
        </p>
        
      </div>

    );

  };


  // This can likely be re-written, pulled out of a useEffect and into an onSubmit event handler on JSX.
  useEffect(() => {
    
    if ( shouldSubmit ) {

      submit();

      setShouldSubmit( false );

    };

    async function submit() {

      setAppData( ( appData: AppData ) => {

        return {
          ...appData,
          page: "search-results",
          submitCount: appData.submitCount + 1,
          rawQuery: searchInput,
          query: searchInput.trim().toLowerCase()
        };

      });
      
    };

  }, [ searchInput, shouldSubmit ]);





  // focus input element on load.... try to turn this to an event handler set on searchbar-container's onLoad property onLoad={ handleLoad }
  useEffect(() => {

    if ( searchInputRef.current && focused ) {

      searchInputRef.current.focus();

    }
  
  }, [ searchInputRef, focused ]);
  







  // I could try a onFocus attribute on the searchbar-container. onFocus, we add a class to e.target.styles or e.target.classList and then we also make the suggestions appear.
  return (

    < div id="searchbar-container" className={ `${ styles.container } ${ focused ? styles.focusedContainer : "" }` } >

      <div

        id="fake-search-form"

        className={`

          ${ styles.fakeSearchForm }

          ${ styles.blurredForm }

          ${ styles.whiteGlass }

          ${ !suggestionsVisible && page === "home" ? styles.flexVisible : styles.hidden }

        `}

      >
        <i

          className={`

            ${ focused ? "" : "fa fa-search" }

            ${ styles.faIcons }

          `}

        >
        </i>

        <div
          id="fake-input"

          className={`

            ${ styles.fakeSearchInput }
            
          `}

          onClick={ handleFocus }
          
        >
          { page === "home" ?  "Tap to learn 100,000,000 molecules" :  "Search" }
        </div>

      </div>

      <form

        id="search-form"

        className={`

          ${ styles.searchForm }

          ${ suggestionsVisible || focused ? styles.focusedForm : styles.blurredForm }

          ${ page === "home" && !focused ? styles.whiteGlass : page === "home" && focused ? styles.lightGreenGlass : styles.darkerTintedGlass }

          ${ styles.whiteColor }
          
          ${ suggestionsVisible || page === "search-results" ? styles.flexVisible : styles.hidden }

        `}

      >

        <i

          className={`

            ${ focused ? "fa fa-arrow-left" : "fa fa-search" }

            ${ styles.faIcons }

          `}

          onClick={ focused ? handleBlur : () => null }

        >
        </i>

        <input

          type="search"

          id="input-field"

          className={`

            ${ styles.searchInput }

            
          `}

          placeholder={ page === "home" && !focused ?  "Tap to learn 100,000,000 molecules" :  "Search" }

          ref={ searchInputRef }


          onFocus={ page=== "search-results" ? handleFocus : undefined }



          // onClick={ ( e ) => {
          //   e.target.blur()
          // } }

          // onFocus={ ( e ) => {
          //   e.preventDefault()
          //   e.target.blur()
          // }}

          // readOnly={ focused ? false : true }
          // onFocus={ handleFocus }



          // onTouchStart={ ( e ) => {
          //   e.preventDefault();
          //   e.stopPropagation();
          // }}



          onChange={ handleInputChange }

          onKeyDown={ handleKeyDown }

          
          autoComplete="off"

          spellCheck="false"

          value={ searchInput }

        />

        <i

          className={`

            ${ focused ? "fa fa-search" : styles.hidden }

            ${ styles.faIcons }

          `}

          onClick={(e: any) => {

            e.preventDefault();

            if( focused ) handleSubmit();

          }}


        >
        </i>

      </ form >

      <div
 
        id="suggestions-container"

        className={`

          ${ styles.suggestionsContainer }
          
          ${ suggestionsVisible ? styles.visible : styles.hidden }

          ${ page === "home" && focused ? styles.lightGreenGlass : page === "search-results" && focused ? styles.darkerTintedGlass : styles.whiteGlass }

        `}

        onClick={ (e) => e.stopPropagation() }

      >

        { suggestions }

      </div>

    </div>

  );
};























// ${ page === "home" ? styles.whiteColor : styles.darkGrayColor }































































// value={ searchInput.charAt(0).toUpperCase() + searchInput.slice(1) } --> .upperCaseFirstLetter()

// sugggestions-container inline styles
/*
style={
  page === "home" && !focused 
  ? 
  { background: 'rgba(255, 255, 255, 0.05)', color: '#ffffff' } // dark mode form 
  :
  page === "search-results" && focused 
  ?
  { background: "rgba(0, 0, 0, 0.5)", color: "rgba( 255, 255, 255, 0.5 )" } // dark transparent "glass"
  :
  { background: 'rgba(255, 255, 255, 0.04)', color: 'rgba( 255, 255, 255, 0.5 )' } // light transparent "glass"
}
*/



/*
style={
  page === "home" && !focused 
  ?
  { background: "rgba(255, 255, 255, 0.04)", color: "#ffffff" } // dark mode glass 
  :
  page === "home" && focused 
  ?
  { background: "rgba( 88, 173, 134, 0.80 )", color: "#ffffff" }
  // { background: lightGreen, color: '#ffffff' } // dark mode form
  :
  { background: "rgba(0, 0, 0, 0.5)", color: "rgba( 255, 255, 255, 0.5 )" } // light mode glass
  // { background: "rgba(0, 0, 0, 0.5)", color: "rgba( 255, 255, 255, 0.5 )", border: "2px solid rgba( 0, 0, 0, 0.2 )" } // light mode glass
}
*/




















// Attempt at trying to fix scroll when input/form elements are focused and virutal keyboard pops up:
/*
// document.body.classList.add( "prevent-ios-focus-scrolling" );

window.scrollTo( 0, 0 );

console.log("manual scroll");
// containerRef.current.scrollTo( 0, 0 );

// overlayRef.current.scrollTo( 0, 0 );
console.log("manual scroll executed");



// we can try this on the homeContainer ? if that one onfocus event bubbles?
if ( e.target.nodeName === "INPUT" ) {

  // const x = window.scrollX, y = window.scrollY;

  console.log("INPUT conditional triggered");

  e.target.focus( { preventScroll: true } );

  window.scrollTo( 0, 0 );
  
}
*/


/*
const MAX_KEYBOARD_PROPORTION = .52

const preventScrollWhenSoftKeyboardAppearsIfThereIsPlentyOfRoom = ( e: any ) => {

  // Bail if not iOS
  if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) return

  // Bail if there's too little vertical space and scrolling is necessary
  // if ((e.target.offsetTop + e.target.offsetHeight) / window.innerHeight > MAX_KEYBOARD_PROPORTION) return

  // Fix the position of the body momentarily to prevent user agent scrolling
  var offset = document.body.scrollTop;

  document.body.style.top = ( offset * -1 ) + 'px';

  document.body.classList.add( "prevent-ios-focus-scrolling" );

  setTimeout(() => {

    // Undo it after 500ms, roughly the amount of time focus animation takes iOS
    offset = parseInt( document.body.style.top, 10 );

    document.body.classList.remove( "prevent-ios-focus-scrolling" );

    document.body.scrollTop = ( offset * -1 );

  }, 500);

};
*/









// style={
//   page === "home"
//     ? {
//         background: '#303134',
//         color: '#ffffff',
//         border: '2px solid #303134',
//       } // dark mode form
//     : {
//         background: '#ffffff',
//         color: '#303134',
//         border: '2px solid #303134',
//       } // light mode form
// }


















{/* { suggestion.charAt(0).toUpperCase() + suggestion.slice(1) } */}


// style={
//   page === "home"
//     ? {
//         background: '#303134',
//         color: '#ffffff',
//         border: '2px solid #303134',
//       } // dark mode form
//     : {
//         background: '#303134',
//         color: '#ffffff',
//         border: '2px solid #303134',
//       } // dark mode form
// }

















// page === "home" || mobile 
// ? { background: '#303134', color: '#ffffff', border: '2px solid #303134' } // dark mode form 
// : { background: '#ffffff', color: '#303134', border: '2px solid #303134' } // light mode form
// ? { background: 'rgba(0, 0, 0, 0.3)', color: '#ffffff', border: '2px solid rgba( 0, 0, 0, 0.8 )' } // dark mode form 

// ? { background: '#303134', color: '#ffffff', border: '2px solid #303134' } // dark mode form 
// : { background: '#ffffff', color: '#303134', border: '2px solid #303134' } // light mode form
// ? { background: 'rgba(0, 0, 0, 0.3)', color: '#ffffff', border: '2px solid rgba( 0, 0, 0, 0.8 )' } // dark mode form 
// : { background: 'rgba(0, 0, 0, 0.08)', color: '#303134', border: '2px solid rgba( 0, 0, 0, 0.3 )' } // light mode form




// preventing scroll onFocus on mobile
// useEffect(() => {
//   if (focused) {
//     window.scrollTo(0, 0);
//     // Prevent scrolling when input is focused
//     document.body.style.position = 'fixed';
//   } else {
//     // Re-enable scrolling when input loses focus
//     document.body.style.position = '';
//   }
// }, [focused]);