import { useEffect, useState, memo } from 'react';
import { Home } from './pages/home/home';
import { SearchResults } from './pages/search-results/search-results';

import { AppData } from "./types";


// Change AppData to GlobalState or ApplicationData
class ApplicationData {

  page: string | undefined;

  submitCount: number | undefined;

  query: string | undefined;

  voiceOn: false | undefined;

  constructor() {}

};


function setThemeColor( color: string ): void {

  const themeColor = document.querySelector('meta[name="theme-color"]');

  if (themeColor) themeColor.setAttribute("content", color);

};



export function App(): JSX.Element {

  const [ appData, setAppData ] = useState< AppData >({

    page: "home",

    submitCount: 0,

    rawQuery: "",

    query: "",

    voiceOn: false,

  });

  
  useEffect(() => {

    if ( appData.submitCount > 0 ) {

      setAppData((appData: any) => {

        return {

          ...appData,

          page: "search-results",
          
        };

      });

    }

  }, [ appData.submitCount ]);



  // is this necessary? it seems to be redundant and causing bugs.
  useEffect( () => {

    if( appData.page === "home" ) setThemeColor( "#58AD86" )

    else if( appData.page === "search-results") setThemeColor( "#141414" );

  }, [ appData.page ] )
  


  return appData.page === "home" ? (

    < Home
      
      appData={ appData }

      setAppData={ setAppData }

    />

  ) : (

    < SearchResults
      
      appData={ appData }

      setAppData={ setAppData }

    />

  );
  
}