import { useRef } from 'react';

interface DescriptionsRefs {
  [key: string]: React.RefObject<any>;
}

interface StructuresRefs {
  [key: string]: React.RefObject<any>;
}

interface PropertiesRefs {
  wrapper: React.RefObject<any>;
  title: React.RefObject<any>;
  additionalInfoButton: React.RefObject<any>;
  names: React.RefObject<any>[];
  values: React.RefObject<any>[];
}

interface DomNodes {
  descriptions: DescriptionsRefs;
  structures: StructuresRefs;
  properties: PropertiesRefs;
}

function generateRefs(keys: string[]): { [key: string]: React.RefObject<any> } {
  const result: { [key: string]: React.RefObject<any> } = {};
  keys.forEach((key) => {
    result[key] = useRef();
  });
  return result;
};

function generateRefArray(length: number): React.RefObject<any>[] {
  return Array.from({ length }, () => useRef());
};

export function useDomNodes(): DomNodes {
  const domNodes: DomNodes = {
    descriptions: generateRefs([
      'recordTitle',
      'wrapper',
      'text',
      'box',
      'opening',
      'openingTitle',
      'openingTitleNumber',
      'mechanism',
      'mechanismTitle',
      'mechanismTitleNumber',
      'toxicity',
      'toxicityTitle',
      'toxicityTitleNumber',
      'structuralTitleNumber',
      'structuralTitle',
      'structural',
    ]) as DescriptionsRefs,

    structures: generateRefs([
      'title2d',
      'title3d',
      'lineStructureBox',
      'molecularGeometryBox',
      'display2d',
      'display3d',
    ]) as StructuresRefs,

    properties: {
      ...generateRefs([
        'wrapper',
        'title',
        'additionalInfoButton',
      ]),
      names: generateRefArray(12),
      values: generateRefArray(12),
    } as PropertiesRefs,
  };

  return domNodes;
};