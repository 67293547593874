import { useEffect, useState } from "react";

// Fn decription:
/*
  on every re-size event fn checks for cutoff condition. 
  fn returns cutoff boolean.
*/
export function useResponsiveLayout( upperBound: number, lowerBound: number): boolean {

  const [ cutoff, setCutoff ] = useState( window.innerWidth <= upperBound && window.innerWidth >= lowerBound);

  useEffect(() => {

    function handleResize() {
      setCutoff( window.innerWidth <= upperBound && window.innerWidth >= lowerBound );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);

  return cutoff;

};