// '#58AD86'
//@ts-nocheck
import * as THREE from 'three';
import { useRef, useState } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

// lightGreen = 58AD86;

// darkGreen = 3B5A4D;


export function BackgroundMolecule( { speed = 0.01, depth = 5, easing = (x) => Math.sqrt(1 - Math.pow(x - 1, 2)), mobile } ) {

  return (
    
    // performance: set antialias: false + clamp dpr to 1.5
    < Canvas gl={ { antialias: true } } dpr={ [ 1, 2 ] } camera={ { position: [0, 0, 1], fov: 10, near: 0.01, far: depth + 5 } } >

      < color attach="background" args={[ `${ mobile ?  "#58AD86" : "#141414" }` ]} /> 

      < ambientLight position={ [ 0, 0, 0 ] } intensity={0.1} />

      < directionalLight position={ [ -5, 5, -5 ] } penumbra={1} intensity={1} color={'white'} />

      < directionalLight position={ [ 0, 0, 2 ] } penumbra={1} intensity={0.5} color={'white'} />
      
      < Cys z={ 0 } speed={speed} index={0} scale={ mobile ? 0.009 : 0.010 } />

    </ Canvas >

  );

};

/**
 * 
 * This is a great example of a proper react component.
 * 
 * It is composed of purely variables, hooks and returning jsx. Event handlers could be added too, but not needed here.
 * 
 */
function Cys( { index, z, speed, scale, ...props } ) {

  const ref = useRef();

  const { viewport, camera } = useThree();

  const { width, height } = viewport.getCurrentViewport( camera, [ 0, 0, -z ] );

  const { nodes, materials } = useGLTF( "/model/cys-t.glb" );

  const initialY = window.innerWidth < 950 ? ( -(height / 2) + (height / 4) ) : ( -(height / 2) + (height / 10) )

  const maxY = ( (height / 2) + (height / 6.5) ); // offscreen - above the top of the viewport

  const minY = ( -(height / 2) - (height / 7) );  // offscreen - below the bottom of the viewport

  const [ data ] = useState({

    y: initialY,

    x: 0,

    spin: THREE.MathUtils.randFloat( 8, 12 ),   // spin speed
    
    rX: Math.random() * Math.PI,                // random x-rotation

    rZ: Math.random() * Math.PI                 // random z-rotation
  
  });

  useFrame( ( state, dt ) => {

    if ( dt < 0.1 ) {
      ref.current.position.set(
        ( index === 0 ? 0 : data.x * width ),   // x
        ( data.y += dt * speed ),               // y
        ( -z )                                  // z
      );
    };

    ref.current.rotation.set(
      ( data.rX += dt / data.spin ),                                          // x
      ( Math.sin( index * 1000 + state.clock.elapsedTime / 10 ) * Math.PI ),  // y
      ( data.rZ += dt / data.spin )                                           // z
    );

    if ( ref.current.position.y > maxY ) data.y = minY  // reset y-position if model floats too high

  });

  return (

    < group scale={ scale } ref={ref} {...props} dispose={null} >

      < group >

        < mesh geometry={nodes.cysMesh.geometry} material={materials.N} />

        < mesh geometry={nodes.cysMesh_1.geometry} material={materials.O} />

        < mesh geometry={nodes.cysMesh_2.geometry} material={materials.C} />

        < mesh geometry={nodes.cysMesh_3.geometry} material={materials.H} />

        < mesh geometry={nodes.cysMesh_4.geometry} material={materials.S} />

      </ group >

    </ group >

  );

};













{/* <OrbitControls 
// Disabling Zoom and z+x axis rotation
enableZoom={false}
minPolarAngle={Math.PI / 2}
maxPolarAngle={Math.PI / 2}
/>  */}





// Other Models:
/*

< Trp type={"left"} z={ 3 } speed={speed * 0.66} index={0} /> 
< Trp type={"right"} z={ 5 } speed={speed * 0.66} index={0} />

{Array.from({ length: (count/5) }, (_, i) => <GlossyTrp key={i} index={i} z={Math.round((i / count) * depth) + 1.2} speed={speed} /> )}
{Array.from({ length: (count/10) }, (_, i) => <GlossyDDT key={i} index={i} z={Math.round((i / count/5) * depth) + 3} speed={speed} /> )}

{Array.from({ length: (count) }, (_, i) => <GlossyTrp key={i} index={i} z={Math.round((i / (count/3)) * depth) + 2} speed={speed} /> )} 
{Array.from({ length: (count * 0.1) }, (_, i) => <GlossyDDT key={i} index={i} z={ Math.round((i / (count/10)) * depth) + 3 } speed={speed} />) }


function Trp({ type, index, z, speed, ...props }) {

  const ref = useRef();
  const { viewport, camera } = useThree()
  const { width, height } = viewport.getCurrentViewport(camera, [0, 0, -z])
  const { nodes, materials } = useGLTF('/model/trp-t.glb');

  // Local state
  const [data] = useState({
    // How fast objects spin, randFlost gives us a value between min and max, in this case 8 and 12
    spin: THREE.MathUtils.randFloat(8, 12),
    // Random rotations, Math.PI represents 360 degrees in radian
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI
  })

  useFrame((state, dt) => {
    // Make the X position responsive, slowly scroll objects up at the Y, distribute it along the Z
    // dt is the delta, the time between this frame and the previous, we can use it to be independent of the screens refresh rate
    // Cap dt at 0.1 so that it cannot run if user switches tabs/windows, animation simply stops.
    // if (dt < 0.1) ref.current.position.set(index === 0 ? 0 : data.x * width, (data.y += dt * speed), -z)
    if (dt < 0.1) ref.current.position.y += dt * speed * 0.5
    
    // Rotate the object around
    ref.current.rotation.set((data.rX += dt / data.spin), Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, (data.rZ += dt / data.spin))
    // If object floats too far up, set them back to the bottom
    if ( ref.current.position.y > ( height/2 + height / 4) ) data.y = -height/2 - height / 4
  })

  const topLeftPosition = [ -0.4, 0.18, -2.7 ];
  const bottomRightPosition = [ 0.60, -0.3, -4.75 ];

  // Using drei's detailed reduces the vertex count. Good for background objects because
  // we don't need high resolution for objects in the distance. The model contains 3 decimated meshes ...
  return (
    <Detailed ref={ref} distances={[0, 65, 80]} position={ type==="left" ? topLeftPosition : bottomRightPosition }>
      <group scale={ 0.004 } {...props} dispose={null}>
        <mesh geometry={nodes.Sphere005.geometry} material={materials.O} />
        <mesh geometry={nodes.Sphere005_1.geometry} material={materials.C} />
        <mesh geometry={nodes.Sphere005_2.geometry} material={materials.H} />
        <mesh geometry={nodes.Sphere005_3.geometry} material={materials.N} />
      </group>
    </Detailed>
  );
};


function GlossyCys({ index, z, speed, ...props }) {
  const ref = useRef();
  const { viewport, camera } = useThree()
  const { width, height } = viewport.getCurrentViewport(camera, [0, 0, -z])
  const { nodes, materials } = useGLTF('/glossy-cys-transformed.glb');

  // Local state:
  const [data] = useState({
    y: THREE.MathUtils.randFloatSpread(height * 2),
    x: THREE.MathUtils.randFloatSpread(2),
    spin: THREE.MathUtils.randFloat(8, 12),
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI
  })

  useFrame((state, dt) => {
    if (dt < 0.1) ref.current.position.set(index === 0 ? 0 : data.x * width, (data.y += dt * speed), -z)
    ref.current.rotation.set((data.rX += dt / data.spin), Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, (data.rZ += dt / data.spin))
    if (data.y > height * (index === 0 ? 4 : 1)) data.y = -(height * (index === 0 ? 4 : 1))
  })

  return (
    <Detailed ref={ref} distances={[0, 65, 80]}>
      <group {...props} scale={ 0.015 } dispose={null}>
        <mesh geometry={nodes.Sulfur.geometry} material={materials['Sulfur.001']} position={[1.96, -0.06, -1.38]} scale={0.51} />
        <mesh geometry={nodes.Oxygen.geometry} material={materials['Oxygen.001']} position={[-1.64, 0.81, -1.43]} scale={0.37} />
        <mesh geometry={nodes.Nitrogen.geometry} material={materials['Nitrogen.001']} position={[-0.45, 0.19, 1.11]} rotation={[-1.58, -0.47, 2.7]} />
        <mesh geometry={nodes.Carbon.geometry} material={materials['Carbon.001']} position={[0.5, 0.01, 0.33]} rotation={[-1.41, 0.47, 1.59]} />
        <mesh geometry={nodes.Hydrogen.geometry} material={materials['Hydrogen.001']} position={[-1.32, 0.44, 1.81]} rotation={[0.88, -0.37, -2.01]} />
      </group> 
    </Detailed>
  );
};


function GlossyDDT({ index, z, speed, ...props }) {
  const ref = useRef();
  const group = useRef();
  const { viewport, camera } = useThree()
  const { width, height } = viewport.getCurrentViewport(camera, [0, 0, -z])
  const { nodes, materials } = useGLTF('/model/ddt-t.glb');

  const [data] = useState({
    y: THREE.MathUtils.randFloatSpread(height * 2),
    x: THREE.MathUtils.randFloatSpread(2),
    spin: THREE.MathUtils.randFloat(8, 12),
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI
  })

  // useFrame executes 60 times per second
  useFrame((state, dt) => {
    if (dt < 0.1) ref.current.position.set(index === 0 ? 0 : data.x * width, (data.y += dt * speed), -z)
    ref.current.rotation.set((data.rX += dt / data.spin), Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, (data.rZ += dt / data.spin))
    if (data.y > height * (index === 0 ? 4 : 1)) data.y = -(height * (index === 0 ? 4 : 1))
  })

  return (
    <Detailed ref={ref} distances={[0, 65, 80]}>
      <group ref={group} {...props} scale={ 0.01 } dispose={null}>
        <mesh geometry={nodes.Chlorine.geometry} material={materials.Chlorine} position={[0.78, 0.07, 3.02]} rotation={[0.97, -0.11, -0.97]} />
        <mesh geometry={nodes.Carbon.geometry} material={materials['Carbon.001']} position={[-0.02, 0.14, 1.77]} rotation={[-1.17, 0.77, -0.01]} />
        <mesh geometry={nodes.Hydrogen.geometry} material={materials['Hydrogen.001']} position={[0, 1.2, 1.18]} rotation={[0.15, -0.82, 3.12]} />
      </group>
    </Detailed >
  );
};
*/
 


// Dramatic Lighting: 
// camera.rotation: [ -3.141592653589793, 0.955660218608898, 3.141592653589793 ], 


// Additional Background Colors:
/*
#ffd863
args={['#ffbf40']} yellow
args={['#2baf83']} green
args={['#000000']} black
*/


