export default [
  "Antabuse",
  "Anatoxin-A",
  "Azobenzene", // molecular switch
  "Vitamin A",
  "Plan B",
  "Melanin",
  "Ibogaine",
  "Paxlovid",
  "Crack",
  "Morphine",
  "Menthol",
  "Rohypnol",
  "Rapamycin",
  "Vitamin C",
  "Spermidine",
  "TETS",
  "Cinnamon",
  "Vitamin D3",
  "Benadryl",
  "Viagra",
  "Ayahuasca",
  "Adrenaline",
  "Sugar",
  "Penicillin",
  "Testosterone",
  "Estrogen",
  "LSD",
  "Acyclovir",
  "Hyaluronic Acid",
  "Serotonin",
  "Melatonin",
  "Birth Control",
  "Thalidomide",
  "Histamine",
  "Claritin",
  "Zyrtec",
  "Allegra",
  "Nicotine",
  "Salicylic Acid",
  "Omega 3 Fatty Acid",
  "Cyclic AMP",
  "Amsacrine",
  "ATP",
  "2,4,6-Trinitrotoluene",
  "Streptomycin",
  "Ciprofloxacin",
  "MeIQ",
  "Zyklon B",
  "Epibatidine",
  "Urethane",
  "Oxycodone",
  "Fentanyl",
  "Cocaine",
  "Capsaicin",
  "Hydrogen Peroxide",
  "Vitamin B6",
  "Vitamin K2",
  "Niacinamide",
  "Hymenin",
  "2,4,6-Tris(1-aziridinyl)-s-triazine",
  "TNT",
  "DDT",
  "NNK",
  "MOCA",
  "GABA",
  "Falcarindiol",
  "Buckminsterfullerene",
  "L-Carnitine",
  "Phospholipid",
  "Hydroxychloroquine",
  "Amoxicillin",
  "Tylenol",
  "Xanax",
  "Alpha-GPC",
  "Dopamine",
  "Levothyroxine",
  "Creatine",
  "Trenbolone Acetate",
  "Huperzine A",
  "Adenosine",
  "Phosgene",
  "Skatole",
  "Fucitol",
  "Fagomine",
  "Pimpinellin",
  "Vaginidiol",
  "Urospermal",
  "Orotic Acid",
  "Spermine",
  "Tretinoin",
  "Tazarotene",
  "Adapalene",
  "Cycasin",
  "Diminazene",
  "Empagliflozin",
  "Feprosidnine",
  "Mesocarb",
  "Timolol", // beta blocker --> Could be a whole category:
  "Hipposudoric Acid", // CID: 71308217 --> This name doesn't return a result for structures
  "Tosyl Azide",
  "5-MeO-DMT",
  "DMDO",
  "Alcohol",
  "Ethanol",
  "Beer",
  "Booze",
  "Acetic Anhydride",
  "Carvone",
  // Benzos
  "Klonopin",
  "Clonazepam",
  // Nitrogenous Bases
  "Adenine",
  "Thymine",
  "Guanine",
  "Cytosine",
  "Uracil",
  // Neurotoxins and addons:
  "Tetrodotoxin",
  "Strychnine",
  "Caramboxin",
  "Tetraethylammonium",
  "Domoic Acid",
  "Methyl Mercury",
  "Toxiferine",
  "Cyperquat",
  "MPPP",
  "MPTP",
  "MPP+",
  "MPPE",
  "Tubocurarine Chloride",
  "Brucine",
  "Annonacin",
  "Chlorpyrifos",
  "TBPO",
  "EBOB",
  "Neosaxitoxin",
  "Batrachotoxin",
  "Resiniferatoxin",
  "Oxidopamine",
  "5,7-Dihydroxytryptamine",
  "p-Chloroamphetamine",
  "Penitrem A",
  "Roxicodone",
  "Tadalafil",
  "Vardenafil",
  "Grubbs Catalyst",
  "Sirolimus",
  "Chlordiazepoxide",
  "Librium",
  "Flunitrazepam",
  "Alprazolam",
  "Flurazepam",
  "Dalmane",
  "Lorazepam",
  "Bisphenol-A",
  "BPA",
  "beta-Carotene",
  "Dewar Benzene",
  "Ladenburg Benzene",
  "Vernolepin",
  "Helenalin",
  "Dramamine",
  "Roundup",
  "9-BBN",
  "Percoset",
  "Octyl Decyl Dimethyl Ammonium Chloride", // active ingredient - Up & Up Disinfecting Wipes 
  "Dioctyl Dimethjyl Ammonium Chloride", // active ingredient - Up & Up Disinfecting Wipes 
  "Didecyl Dimethyl AMmonium Chloride", // active ingredient - Up & Up Disinfecting Wipes
  "Cortisol",
  "Pantothenic Acid",
  "Urushiol",
  // bottom 4 would be great to test linknig feature with as bottom 3 are mentioned inside of Pthalimide's description
  "Phthalimide",
  "Anthranilic Acid",
  "Saccharin ",
  "5-Nitro-ortho-toluidine", // azo dyes, wow.
  "Paraldehyde",
  "Tongkat Ali",
  "Eurycomanone",
  "Ceramides",
  "Ceramide 3",
  "Parabens",
  "Methylparaben",
  "Pthalates",
  "Di-n-butyl Phthalate",
  // Anithistamines
  "Fexofenadine",
  "Loratadine",
  "Cetirizine",
  "Diphenhydramine",
  "Chlorpheniramine",
  // Carinogens line 7-823
  "1,1,1,2-Tetrachloroethane",
  "1,1,1-Trichloroethane",
  "1,1,2,2-Tetrachloroethane",
  "1,1,2-Trichloroethane",
  "1,1-Dimethylhydrazine",
  "1,2,3-Trichloropropane",
  "1,2-Diamino-4-nitrobenzene",
  "1,2-Dibromo-3-chloropropane",
  "1,2-Dichloroethane",
  "1,2-Dichloropropane",
  "1,2-Diethylhydrazine",
  "1,2-Dihydroaceanthrylene",
  "1,2-Dimethylhydrazine",
  "1,2-Diphenylhydrazine",
  "1,2-Epoxybutane",
  "1,3-Butadiene",
  "1,3-Dichloro-2-propanol",
  "1,3-Dichloropropene",
  "1,3-Dinitropyrene",
  "1,3-Propane Sultone",
  "1,4-Diamino-2-nitrobenzene",
  "1,4-Dichloro-2-nitrobenzene",
  "1,4-Dimethylphenanthrene",
  "1,4-Dioxane",
  "1,5-Naphthalene diisocyanate",
  "1,5-Naphthalenediamine",
  "1,6-Dinitropyrene",
  "1,8-Dinitropyrene",
  "1-Amino-2,4-dibromoanthraquinone",
  "1-Amino-2-methylanthraquinone",
  "1-Bromo-3-chloropropane",
  "1-Bromopropane",
  "1-Butyl glycidyl ether",
  "1-Chloro-2-methylpropene",
  "1-Hydroxyanthraquinone",
  "1-Methylchrysene",
  "1-Methylphenanthrene",
  "1-Naphthylamine",
  "1-Naphthylthiourea",
  "1-Nitronaphthalene",
  "1-Nitropyrene",
  "1-tert-Butoxypropan-2-ol",
  "11-Aminoundecanoic acid",
  "11H-Benz[bc]aceanthrylene",
  "13H-Dibenzo[a,g]fluorene",
  "2,2',5,5'-Tetrachlorobenzidine",
  "2,2-Bis(bromomethyl)propane-1,3-diol",
  "2,3,4,7,8-Pentachlorodibenzofuran",
  "2,3-Dibromopropan-1-ol",
  "2,4'-Diphenyldiamine",
  "2,4,5-Trimethylaniline",
  "2,4,6-Trichlorophenol",
  "2,4,6-Trimethylaniline",
  "2,4-D",
  "2,4-dichlorophenoxyacetic acid)",
  "2,4-Diaminoanisole",
  "2,4-Diaminotoluene",
  "2,4-Dichloro-1-nitrobenzene",
  "2,4-Dinitrotoluene",
  "2,4-Hexadienal",
  "2,4-Xylidine",
  "2,5-Diaminotoluene",
  "2,5-Xylidine",
  "2,6-Dimethylaniline", 
  "2,6-Xylidine",
  "2,6-Dinitrotoluene",
  "2-(1-Aziridinyl)ethanol",
  "2-Amino-4-chlorophenol",
  "2-Amino-4-nitrophenol",
  "2-Amino-5-nitrophenol",
  "2-Amino-5-nitrothiazole",
  "2-Aminoanthraquinone",
  "2-Bromopropane",
  "2-Butoxyethanol",
  "2-Chloro-1,1,1-trifluoroethane",
  "2-Ethylhexyl acrylate",
  "2-Mercaptobenzothiazole",
  "2-Methyl-1-nitroanthraquinone",
  "2-Methylaziridine",
  "Propyleneimine",
  "2-Methylchrysene",
  "2-Methylfluoranthene",
  "2-Methylimidazole",
  "2-Naphthylamine",
  "2-Nitrofluorene",
  "2-Nitronaphthalene",
  "2-Nitropropane",
  "2-Nitropyrene",
  "2-Nitrotoluene",
  "3,3'-Dichlorobenzidine",
  "3,3'-Dimethoxybenzidine",
  "ortho-Dianisidine",
  "3,3'-Dimethylbenzidine",
  "ortho-Tolidine",
  "3,3′,4,4′-Tetrachloroazobenzene",
  "3,4,5,3’,4’-Pentachlorobiphenyl",
  "3,5-Dinitrotoluene",
  "3,7-Dinitrofluoranthene",
  "3,9-Dinitrofluoranthene",
  "3-Carbethoxypsoralen",
  "3-Methylchrysene",
  "3-Methylfluoranthene",
  "3-Monochloro-1,2-propanediol",
  "3-Nitrobenzanthrone",
  "3-Nitrofluoranthene",
  "3-Nitroperylene",
  "4,4',6-Trimethylangelicin",
  "4,4'-Diaminodiphenyl ether",
  "4,4'-Dimethylangelicin",
  "4,4'-Methylene bis(2-methylaniline)",
  "4,4'-Methylenebis(2-chloroaniline)",
  "4,4'-Methylenedianiline",
  "4,4'-Methylenediphenyl diisocyanate",
  "4,4'-Thiodianiline",
  "4,5',8-Trimethylpsoralen",
  "4,5'-Dimethylangelicin",
  "4-Amino-2-nitrophenol",
  "4-Aminobiphenyl",
  "4-Chloro-meta-phenylenediamine",
  "4-Chloro-ortho-phenylenediamine",
  "4-Chloro-ortho-toluidine",
  "4-Chlorobenzotrifluoride",
  "4-Chloronitrobenzene",
  "4-Hydroxyazobenzene",
  "4-Methylchrysene",
  "4-Methylimidazole",
  "4-Nitrobiphenyl",
  "4-Nitropyrene",
  "4-Vinylcyclohexene",
  "4-Vinylcyclohexene diepoxide",
  "4H-Cyclopenta[def]chrysene",
  "5-Aminoacenaphthene",
  "5-Chloro-ortho-toluidine",
  "5-Fluorouracil",
  "5-Methoxypsoralen",
  "5-Methylangelicin",
  "5-Methylchrysene",
  "5-Nitro-ortho-anisidine",
  "5-Nitroacenaphthene",
  "6-Mercaptopurine",
  "6-Methylchrysene",
  "6-Nitrobenzo[a]pyrene",
  "6-Nitrochrysene",
  "7-Methylpyrido[3,4-c]psoralen",
  "7-Nitrobenz[a]anthracene",
  "7H-Dibenzo[c,g]carbazole",
  "8-Hydroxyquinoline",
  "9-Nitroanthracene",
  "PCB 126",
  "ANTU",
  "CCNU",
  "Asparagusic Acid",
  "A-alpha-C",
  "Acenaphthene",
  "Acepyrene",
  "Acetaldehyde",
  "Acetamide",
  "Acetaminophen",
  "Acridine orange",
  "Acriflavinium chloride",
  "Acrolein",
  "Acrylamide",
  "Acrylic acid",
  "Araldite",
  "Acrylonitrile",
  "Actinomycin D",
  "Adriamycin",
  "AF-2",
  "Agaritine",
  "Aldicarb",
  "Aldrin",
  "Anandamide",
  "Allyl Chloride",
  "Allyl Isothiocyanate",
  "Allyl Isovalerate",
  "Benzal Chloride", 
  "Benzotrichloride", 
  "Benzyl Chloride",
  "Benzoyl Chloride",
  "Amaranth",
  "Amitrole",
  "Aniline",
  "Anthanthrene",
  "Anthracene",
  "Anthraquinone",
  "Antimony Trioxide",
  "Antimony Trisulfide",
  "Apholate",
  "Aramite",
  "Arecoline",
  "Aristolochic Acid",
  "Atropine",
  "Atrazine",
  "Auramine",
  "Aurothioglucose",
  "Azacitidine",
  "Azaserine",
  "Azathioprine",
  "Aziridine",
  "Aziridyl Benzoquinone",
  "Benz[a]acridine",
  "Benz[a]anthracene",
  "Benz[c]acridine",
  "Benz[j]aceanthrylene",
  "Benz[l]aceanthrylene",
  "Benzene",
  "Benzidine",
  "Benzo[a]fluoranthene",
  "Benzo[a]fluorene",
  "Benzo[a]pyrene",
  "Benzo[b]chrysene",
  "Benzo[b]fluoranthene",
  "Benzo[b]fluorene",
  "Benzo[b]naphtho[2,1-d]thiophene",
  "Benzo[c]fluorene",
  "Benzo[c]phenanthrene",
  "Benzo[e]pyrene",
  "Benzo[g]chrysene",
  "Benzo[ghi]fluoranthene",
  "Benzo[ghi]perylene",
  "Benzo[j]fluoranthene",
  "Benzo[k]fluoranthene",
  "Benzofuran",
  "Benzophenone",
  "Benzoyl Peroxide",
  "Benzyl Acetate",
  "beta-Butyrolactone",
  "beta-Propiolactone",
  "beta-selinene",
  "Bis(2,3-epoxycyclopentyl)ether",
  "Bis(2-chloro-1-methylethyl)ether",
  "Bis(2-chloroethyl)ether",
  "Bis(2-ethylhexyl) phthalate",
  "Bis(chloromethyl)ether",
  "Bischloroethyl nitrosourea",
  "BCNU",
  "Bisphenol",
  "Bisulfite",
  "Bromochloroacetic Acid",
  "Bromochloroacetonitrile",
  "Bromodichloromethane",
  "Bromoethane",
  "Bromoform",
  "Busulfan",
  "Butyl Benzyl Phthalate",
  "Butyl Methacrylate",
  "Butylated Hydroxyanisole",
  "Butylated Hydroxytoluene",
  "BHT",
  "BHA",
  "Caffeic Acid",
  "Caffeine",
  "Camphor",
  "Cantharidin",
  "Capecitabine",
  "Caprolactam",
  "Captafol",
  "Captan",
  "Carbaryl",
  "Carbazole",
  "Carbon Tetrachloride",
  "Carmoisine",
  "Carnosine",
  "Carrageenan",
  "Catechol",
  "Chloral",
  "Chloral Hydrate",
  "Chlorambucil",
  "Chloramine",
  "Chlordane",
  "Chlordecone",
  "Kepone",
  "Chlordimeform",
  "Chlorendic Acid",
  "Chloroacetonitrile",
  "Chlorobenzilate",
  "Chlorodibromomethane",
  "Chlorodifluoromethane",
  "Chloroethane",
  "Chlorofluoromethane",
  "Chloroform",
  "Chloromethyl Methyl Ether",
  "Chloronitrobenzene",
  "2-Chloronitrobenzene",
  "3-Chloronitrobenzene", 
  "4-Chloronitrobenzene)",
  "Chlorophenols",
  "Chlorophenoxy",
  "Chloroprene",
  "Chloropropham",
  "Chloroquine",
  "Chlorothalonil",
  "Chlorozotocin",
  "Cholesterol",
  "Chrysene",
  "Chrysoidine",
  "CI Acid Orange 20",
  "CI Acid Orange 3",
  "CI Acid Red 114",
  "CI Basic Red 9",
  "CI Direct Black 38",
  "CI Direct Blue 15",
  "CI Direct Blue 218",
  "CI Direct Blue 6",
  "CI Direct Brown 95",
  "CI Orange G",
  "CI Pigment Red 3",
  "Ciclosporin",
  "Cimetidine",
  "Cinnamyl Anthranilate",
  "cis-9,10-Epoxystearic Acid",
  "Cisplatin",
  "Citrinin",
  "Clofibrate",
  "Clomiphene Citrate",
  "Cobalt(II) Oxide",
  "Cobalt(II) Sulfide",
  "Cobalt(II,III) Oxide",
  "Copper 8-hydroxyquinoline",
  "Coronene",
  "Coumarin",
  "Creosotes",
  "Crotonaldehyde",
  "Cumene",
  "Cupferron",
  "Sodium cyclamate",
  "Cyclochlorotine",
  "Cyclohexanone",
  "Cyclopenta[cd]pyrene",
  "Cyclophosphamide",
  "Cyclosporine",
  "d-Limonene",
  "Dacarbazine",
  "Dantron",
  "Dapsone",
  "Daunomycin",
  "DHT",
  "4,4'-dichlorodiphenyltrichloroethane",
  "Decabromodiphenyl Oxide",
  "Deltamethrin",
  "Di(2-ethylhexyl) Adipate",
  "Di(2-ethylhexyl)phthalate",
  "Diacetylaminoazotoluene",
  "Diallate",
  "Diazepam",
  "Diazinon",
  "Diazomethane",
  "Dibenz[a,c]anthracene",
  "Dibenz[a,h]acridine",
  "Dibenz[a,h]anthracene",
  "Dibenz[a,j]acridine",
  "Dibenz[a,j]anthracene",
  "Dibenz[c,h]acridine",
  "Dibenzo-para-dioxin",
  "Dibenzo[a,e]fluoranthene",
  "Dibenzo[a,e]pyrene",
  "Dibenzo[a,h]pyrene",
  "Dibenzo[a,i]pyrene",
  "Dibenzo[a,l]pyrene",
  "Dibenzo[e,l]pyrene",
  "Dibenzo[h,rst]pentaphene",
  "Dibenzothiophene",
  "Dibromoacetic Acid",
  "Dibromoacetonitrile",
  "Dichloroacetic Acid",
  "Dichloroacetonitrile",
  "Dichloroacetylene",
  "Dichloromethane",
  "Dichlorvos",
  "Dicofol",
  "Didanosine",
  "Dieldrin",
  "Diethanolamine",
  "Diethyl Sulfate",
  "Diethylstilbestrol",
  "Diglycidyl Resorcinol Ether",
  "Digoxin",
  "Dihydrosafrole",
  "Dihydroxymethylfuratrizine",
  "Diisopropyl Sulfate",
  "Dimethoxane",
  "Dimethyl Hydrogen Phosphite",
  "Dimethyl Sulfate",
  "Dimethylarsinic Acid",
  "Dimethylcarbamoyl Chloride",
  "Dinitrosopentamethylenetetramine",
  "Diphenylamine",
  "Disperse Blue 1",
  "Disperse Yellow 3",
  "Disulfiram",
  "Dithranol",
  "Doxefazepam",
  "Doxylamine succinate",
  "Droloxifene",
  "Dulcin",
  "Endrin",
  "Eosin",
  "Epichlorohydrin",
  "Erionite",
  "Estazolam",
  "Ethionamide",
  "Ethyl Acrylate",
  "Ethyl Carbamate",
  "Ethyl Methanesulfonate",
  "Ethyl Selenac",
  "Ethyl Tellurac",
  "Ethylbenzene",
  "Ethylene",
  "Ethylene Dibromide",
  "Ethylene Oxide",
  "Ethylene Sulfide",
  "Ethylenethiourea",
  "Etoposide",
  "Eugenol",
  "Evans Blue",
  "Fast Green FCF",
  "Fenvalerate",
  "Ferbam",
  "Ferric Oxide",
  "Fluometuron",
  "Fluoranthene",
  "Fluorene",
  "Fluoro-edenite Fibrous Amphibole",
  "Formaldehyde",
  "Fullerene",
  "Fumonisin B1",
  "Furan",
  "Furazolidone",
  "Furfural",
  "Furfuryl Alcohol",
  "Furosemide",
  "gamma-Butyrolactone",
  "Gemfibrozil",
  "Gentian Violet",
  "Glu-P-1",
  "Glu-P-2",
  "Glycidaldehyde",
  "Glycidol",
  "Glycidyl Methacrylate",
  "Glycidyl Oleate",
  "Glycidyl Stearate",
  "Glyphosate",
  "Griseofulvin",
  "Guinea Green B",
  "Gyromitrin",
  "Haematite",
  "HC Blue No. 1",
  "HC Blue No. 2",
  "HC Red No. 3",
  "HC Yellow No. 4",
  "Heptachlor",
  "Hexachlorobenzene",
  "Hexachlorobutadiene",
  "Hexachlorocyclohexane",
  "Hexachloroethane",
  "Hexachlorophene",
  "Hexamethylphosphoramide",
  "Hyaluronan",
  "Hycanthone Mesylate",
  "Hydralazine",
  "Hydrazine",
  "Hydrochloric Acid",
  "Hydrochlorothiazide",
  "Hydroquinone",
  "Hydroxysenkirkine",
  "Hydroxyurea",
  "Hypochlorite Salts",
  "Indeno[1,2,3-cd]pyrene",
  "Indium Phosphide",
  "Indium Tin Oxide",
  "Iodine-131",
  "IQ",
  "Isatidine",
  "Isobutyl Nitrite",
  "Isonicotinic Acid Hydrazide",
  "Isoniazid",
  "Isophorone",
  "Isophosphamide",
  "Isoprene",
  "Isopropyl Alcohol",
  "Isosafrole",
  "Jacobine",
  "Kaempferol",
  "Kojic acid",
  "Lasiocarpine",
  "Lauroyl Peroxide",
  "Leucogentian Violet",
  "Leucomalachite Green",
  "Lindane",
  "Luteoskyrin",
  "Malachite Green",
  "Malathion",
  "Maleic Hydrazide",
  "Malonaldehyde",
  "Maneb",
  "Mannomustine Dihydrochloride",
  "MeA-alpha-C",
  "Medphalan",
  "Medroxyprogesterone Acetate",
  "MeIQx",
  "Methyl-CCNU",
  "Melamine",
  "Melphalan",
  "Merphalan",
  "meta-Cresidine",
  "meta-Dichlorobenzene",
  "meta-Phenylenediamine",
  "Methimazole",
  "Methotrexate",
  "Methoxsalen",
  "8-methoxypsoralen",
  "Methoxychlor",
  "Methyl Acrylate",
  "Methyl Bromide",
  "Methyl Carbamate",
  "Methyl Chloride",
  "Methyl Iodide",
  "Methyl Isobutyl ketone",
  "Methyl Methacrylate",
  "Methyl Methanesulfonate",
  "Methyl Parathion",
  "Methyl Red",
  "Methyl Selenac",
  "Methyl Tert-butyl Ether",
  "Methylazoxymethanol Acetate",
  "Methylene Blue",
  "Methyleugenol",
  "Methylglyoxal",
  "Methylthiouracil",
  "Metronidazole",
  "Microcystin-LR",
  "Mirex",
  "Mitomycin C",
  "Mitoxantrone",
  "Molly",
  "Molybdenum Trioxide",
  "Monocrotaline",
  "Monomethylarsonic Acid",
  "Methylarsonic Acid",
  "Monuron",
  "Morpholine",
  "Musk Ambrette",
  "Musk Xylene",
  "Mustard Gas",
  "Myleran",
  "N'-Nitrosoanabasine",
  "N'-Nitrosoanatabine",
  "N'-Nitrosonornicotine",
  "N,N'-Diacetylbenzidine",
  "N,N'-Diethylthiourea",
  "Chlornaphazine",
  "N,N-Dimethyl-p-toluidine",
  "N,N-Dimethylacetamide",
  "N,N-Dimethylaniline",
  "N,N-Dimethylformamide",
  "n-Butyl Acrylate",
  "N-Ethyl-N-nitrosourea",
  "N-Methyl-N,4-dinitrosoaniline",
  "N-Methyl-N-nitrosourea",
  "N-Methyl-N-nitrosourethane",
  "N-Methyl-N-nitro-N-nitrosoguanidine",
  "MNNG",
  "N-Methylolacrylamide",
  "N-Nitrosodi-n-butylamine",
  "N-Nitrosodi-n-propylamine",
  "N-Nitrosodiethanolamine",
  "N-Nitrosodiethylamine",
  "N-Nitrosodimethylamine",
  "N-Nitrosodiphenylamine",
  "N-Nitrosofolic Acid",
  "N-Nitrosoguvacine",
  "N-Nitrosoguvacoline",
  "N-Nitrosohydroxyproline",
  "N-Nitrosomethylethylamine",
  "N-Nitrosomethylvinylamine",
  "N-Nitrosomorpholine",
  "N-Nitrosopiperidine",
  "N-Nitrosoproline",
  "N-Nitrosopyrrolidine",
  "N-Nitrososarcosine",
  "N-Phenyl-2-naphthylamine",
  "n-Propyl carbamate",
  "N-Vinyl-2-pyrrolidone",
  "Nafenopin",
  "Naphthalene",
  "Naphtho[1,2-b]fluoranthene",
  "Naphtho[2,1-a]fluoranthene",
  "Naphtho[2,3-e]pyrene",
  "Niridazole",
  "Nithiazide",
  "Nitrate",
  "Nitrite",
  "Nitrilotriacetic Acid",
  "Nitrobenzene",
  "Nitrofen",
  "Nitrofural",
  "Nitrofurantoin",
  "Nitrogen Mustard",
  "Nitrogen Mustard N-oxide",
  "Nitromethane",
  "Nitrotoluenes",
  "Nitrovin",
  "Nodularins",
  "Oleic Acid",
  "Ochratoxin A",
  "Oestradiol Mustard",
  "Oestrogen",
  "Orange G",
  "Orange I",
  "ortho-Aminoazotoluene",
  "ortho-Anisidine",
  "ortho-Dichlorobenzene",
  "ortho-Nitroanisole",
  "ortho-Phenylenediamine",
  "ortho-Phenylenediamine Dihydrochloride",
  "ortho-Phenylphenol",
  "ortho-Toluidine",
  "Oxazepam",
  "Oxyphenbutazone",
  "Palmitic Acid",
  "Palygorskite",
  "Panfuran S",
  "para-Aminoazobenzene",
  "para-Aminobenzoic acid",
  "para-Anisidine",
  "para-Aramid Fibrils",
  "para-Benzoquinone Dioxime",
  "para-Chloroaniline",
  "para-Cresidine",
  "para-Dichlorobenzene",
  "para-Dimethylaminoazobenzene",
  "para-Dimethylaminoazobenzenediazo",
  "para-Nitroanisole",
  "para-Nitrosodiphenylamine",
  "para-Phenylenediamine",
  "para-Quinone",
  "Paracetamol",
  "Parasorbic Acid",
  "Parathion",
  "Patulin",
  "Penicillic Acid",
  "Pentachloroethane",
  "Pentachlorophenol",
  "Pentavalent Antimony",
  "Pentosan Polysulfate Sodium",
  "Perfluorooctanoic Acid",
  "Permethrin",
  "Perylene",
  "Petasitenine",
  "Phenacetin",
  "Phenanthrene",
  "Phenazopyridine Hydrochloride",
  "Phenelzine Sulfate",
  "Phenicarbazide",
  "Phenobarbital",
  "Phenol",
  "Phenolphthalein",
  "Phenoxybenzamine Hydrochloride",
  "Phenyl Glycidyl Ether",
  "Phenylbutazone",
  "Phenytoin",
  "PhIP",
  "Picene",
  "Picloram",
  "Pioglitazone",
  "Piperonyl Butoxide",
  "Ponceau 3R",
  "Ponceau MX",
  "Ponceau SX",
  "bis(2-hydroxyethyl)dithiocarbamate",
  "Prazepam",
  "Prednimustine",
  "Prednisone",
  "Primidone",
  "Procarbazine Hydrochloride",
  "Progestins",
  "Pronetalol Hydrochloride",
  "Propham",
  "Propylene",
  "Propylene Oxide",
  "Propylthiouracil",
  "Ptaquiloside",
  "Pulegone",
  "Pyrene",
  "Pyridine",
  "Pyrido[3,4-c]psoralen",
  "Pyrimethamine",
  "Quercetin",
  "Quinoline",
  "Quintozene",
  "Pentachloronitrobenzene",
  "Reserpine",
  "Resorcinol",
  "Retrorsine",
  "Rhodamine 6G",
  "Rhodamine B",
  "Riddelliine",
  "Rifampicin",
  "Ripazepam",
  "Rosiglitazone",
  "Rugulosin",
  "Safrole",
  "Semicarbazide Hydrochloride",
  "Semustine",
  "Seneciphylline",
  "Senkirkine",
  "Sepiolite",
  "Shrooms",
  "Shikimic Acid",
  "Simazine",
  "Sildenafil",
  "Sodium diethyldithiocarbamate",
  "Sodium ortho-phenylphenate",
  "Spironolactone",
  "Sterigmatocystin",
  "Streptozotocin",
  "Styrene",
  "Styrene-7,8-oxide",
  "Succinic Anhydride",
  "Sudan Brown RR",
  "Sudan I",
  "Sudan II",
  "Sudan III",
  "Sudan Red 7B",
  "Sulfafurazole",
  "Sulfallate",
  "Sulfamethazine",
  "Sulfamethoxazole",
  "Sulfasalazine",
  "Sulfur Dioxide",
  "Sulfur Mustard",
  "Sunset Yellow FCF",
  "Symphytine",
  "T2-Trichothecene",
  "Tamoxifen",
  "TATP",
  "Tannic Acid",
  "Tannins",
  "Temazepam",
  "Teniposide",
  "Terpene Polychlorinates",
  "Strobane",
  "Tetrabromobisphenol A",
  "Tetrachloroethylene",
  "Perchloroethylene",
  "Tetrachlorvinphos",
  "Tetrafluoroethylene",
  "Tetrahydrofuran",
  "Tetranitromethane",
  "Theobromine",
  "Theophylline",
  "Thioacetamide",
  "Thiotepa",
  "Thiouracil",
  "Thiourea",
  "Thiram",
  "Toluene",
  "Toluene Diisocyanates",
  "Toremifene",
  "Toxaphene",
  "trans-1,4-Dichlorobutene",
  "Treosulfan",
  "Triamterene",
  "Trichlorfon",
  "Trichlormethine",
  "Trichloroacetic Acid",
  "Trichloroacetonitrile",
  "Trichloroethylene",
  "Triethanolamine",
  "Triethylene Glycol Diglycidyl Ether",
  "Trifluralin",
  "Triglyceride",
  "Trimethylolpropane Triacrylate",
  "Triphenylene",
  "Tris(1-aziridinyl)phosphine Oxide",
  "Tris(2,3-dibromopropyl) Phosphate",
  "Tris(2-chloroethyl) Phosphate",
  "Triaziquone",
  "Trivalent Antimony",
  "Trp-P-1",
  "Trp-P-2",
  "Trypan Blue",
  "Uracil Mustard",
  "Vanadium Pentoxide",
  "Vinblastine Sulfate",
  "Vincristine Sulfate",
  "Vinyl Acetate",
  "Vinyl Bromide",
  "Vinyl Chloride",
  "Vinyl Fluoride",
  "Vinyl Toluene",
  "Vinylidene Chloride",
  "Vinylidene Fluoride",
  "Wollastonite",
  "Yellow AB",
  "Yellow OB",
  "Zalcitabine",
  "Zectran",
  "Zidovudine",
  "AZT",
  "Zineb",
  "Ziram",
  "Methylstyrene",
  "Myrcene",
  "Picoline",
  "Allene",
  "Ethylene Glycol",
  "Choline",
  "Ethane",
  "Lisinopril",
  "Zestril",
  "Synthroid",
  "Atorvastatin",
  "Lipitor",
  "Metformin",
  "Glucophage",
  "Simvastatin",
  "Zocor",
  "Omeprazole",
  "Prilosec",
  "Amlodipine",
  "Norvasc",
  "Metoprolol",
  "Lopressor",
  "Ventolin",
  "Albuterol",
  "Hydrocodone",
  "Ativan",
  "Valium",
  "Ambien",
  "Squalene",
  "Titanium Dioxide",
  "Limonene",
  "Narcan",
  "Codeine",
  "Methadone",
  "Heroin",
  "Methamphetamine",
  "Amphetamine",
  "MDMA",
  "DMT",
  "Cannabis",
  "THC",
  "Cannabinol",
  "Cannabidiol",
  "Cannabigerol",
  "Norepinephrine",
  "Epinephrine",
  "Glutamate",
  "Acetylcholine",
  "Adderal",
  "Vyvanse",
  "Glucose",
  "Fructose",
  "Sucrose",
  "Lactose",
  "Maltose",
  "Galactose",
  "Mannose",
  "Accutane",
  "Vitamin B1",
  "Vitamin B2",
  "Vitamin B3",
  "Vitamin B5",
  "Vitamin B7",
  "Vitamin B9",
  "Vitamin B12",
  "Vitamin D2",
  "Vitamin E",
  "Vitamin K",
  "Methenolone",
  "Methandrostenolone",
  "Methandienone",
  "Methasterone",
  "Methyldienedione",
  "Methyldrostanolone",
  "Zolpidem",
  "Imidazole",
  "Indole",
  "Crystal Meth",
  "2-bromobutane",
  "Catecholamine",
  "Tolulene",
  "Anisole",
  "Biotin",
  "DNT",
  "Glyceraldehyde",
  "Threose",
  "Allose",
  "Ascorbic Acid",
  "Psilocybin",
  "Aalicyclic Acid",
  "Modafinil",
  "Strattera",
  "Sodium Hyaluronate",
  "Octinoxate",
  "Nicotinamide",
  "Aspirin",
  // Common o-chem reagents:
  "THF",
  "PCC",
  "Chromic Acid",
  // Amino Acids: 
  "Glycine",
  "Alanine",
  "Threonine",
  "Tyrosine",
  "Phenylalanine",
  "Tryptophan",
  "Valine",
  "Leucine",
  "Isoleucine",
  "Proline",
  "Serine",
  "Cysteine",
  "Selenocysteine",
  "Methionine",
  "Arginine",
  "Histidine",
  "Lysine",
  "Aspartate",
  "Aspartic Acid",
  "Glutamic Acid",
  "Asparagine",
  "Glutamine",
  // Skin Care
  "Avobenzone",
  "Glycolic Acid",
  // Antibiotics
  "Tetracycline",
  "Erythromycin",
  "Chloramphenicol",
  "Penicillin V",
  "Ampicillin",
  //
  "Cinnamaldehyde",
  // Hypertrophy
  "Ostarine",
  "Primobolan",
  "Dianabol",
  "Anadrol",
  "Winstrol",
  "Deca Durabolin",
  "Trenbolone",
  "Anavar",
  "Equipoise",
  "Masteron",
];


// These are names no one is likely to search. Especially not laymen.
const bigNames = [
  "1,2:3,4-Diepoxybutane",
  "1,2-Bis(chloromethoxy)ethane",
  "1,2,3-Tris(chloromethoxy)propane",
  "1,4-Butanediol dimethanesulfonate",
  "Tris(aziridinyl)-para-benzoquinone",
  "2,6-Dichloro-para-phenylenediamine",
  "1,4-Bis(chloromethoxymethyl)benzene",
  "5,6-Cyclopenteno-1,2-benzanthracene",
  "3-(N-Nitrosomethylamino)propionitrile",
  "N,N-Bis(2-chloroethyl)-2-naphthylamine",
  "2,3,7,8-Tetrachlorodibenzo-para-dioxin",
  "3-(N-Nitrosomethylamino)propionaldehyde",
  "2-Amino-3-methylimidazo[4,5-f]quinoline)",
  "3,3'-Dichloro-4,4'-diaminodiphenyl ether",
  "3-Amino-1-methyl-5H-pyrido[4,3-b]indole)",
  "3-Chloro-2-methylpropene, technical grade",
  "3,3'-Dimethoxybenzidine-4,4'-diisocyanate",
  "(2-Aminodipyrido[1,2-a:3',2'-d]imidazole)",
  "Tris(2-methyl-1-aziridinyl)phosphine oxide",
  "1-(2-Chloroethyl)-3-cyclohexyl-1-nitrosourea",
  "1-(2-Chloroethyl)-3-cyclohexyl-1-nitrosourea",
  "Bis(1-aziridinyl)morpholinophosphine sulfide",
  "3-Amino-1,4-dimethyl-5H-pyrido[4,3-b]indole)",
  "N-[4-(5-Nitro-2-furyl)-2-thiazolyl]acetamide",
  "2-Amino-3,4-dimethylimidazo[4,5-f]quinoline)",
  "2-Amino-5-(5-nitro-2-furyl)-1,3,4-thiadiazole",
  "5-(Morpholinomethyl)-3-[(5-nitrofurfurylidene)",
  "2-Amino-3,8-dimethylimidazo[4,5-f]quinoxaline)",
  "2-Amino-6-methyldipyrido[1,2-a:3',2'-d]imidazole)",
  "4-(N-Nitrosomethylamino)-1-(3-pyridyl)-1-butanone",
  "2-(2-Formylhydrazino)-4-(5-nitro-2-furyl)thiazole",
  "1-[(5-Nitrofurfurylidene)amino]-2-imidazolidinone",
  "3-Chloro-4-(dichloromethyl)-5-hydroxy-2(5H)-furanone",
  "1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea",
  "1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea",
  "[1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea]",
  "3,4-Epoxy-6-methylcyclohexylmethyl-3,4-epoxy-6-methylcyclo-hexanecarboxylate",
]